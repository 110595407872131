import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

const ConductExame = ({ onAssignExame }) => {
  const [exameLink, setExameLink] = useState('');
  const [previewLink, setPreviewLink] = useState('');

  const handlePreview = () => {
    setPreviewLink(exameLink);
  };

  const handleAssign = () => {
    window.alert('Exam has been assigned successfully!');
    onAssignExame(exameLink); // Pass the link to the parent component
    setExameLink(''); // Clear the input field
  };

  return (
    <Box sx={{ width:"100%", marginTop:4, paddingLeft:2,paddingRight:2}}>
      <Typography variant="h4" gutterBottom>
        Conduct Exame
      </Typography>

      {/* Input Field */}
      <TextField
        label="Google Form Link"
        variant="outlined"
        fullWidth
        value={exameLink}
        onChange={(e) => setExameLink(e.target.value)}
        sx={{ marginBottom: 2 }}
      />

      {/* Preview Button */}
      <Button variant="contained" color="primary" onClick={handlePreview} sx={{ marginBottom: 2 }}>
        Preview Form
      </Button>

      {/* Form Preview */}
      {previewLink && (
        <Box sx={{ width: '600', height: '600px', marginTop: 2 }}>
          <iframe
            src={previewLink}
            width="100%"
            height="100%"
            title='preview'
          >
            Loading…
          </iframe>
          <Button variant="contained" color="primary" onClick={handleAssign} sx={{ marginBottom: 2, marginTop:2 }}>
        Assign Exame
      </Button>
        </Box>
        
      )}
      
    </Box>
  );
};

export default ConductExame;
