import React, { useEffect, useState } from "react";
import { TextField, Typography, Box, Button, CircularProgress, Container, Paper, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getUIDLocally } from "../../../utils/userUtils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const db = firebase.firestore();

export default function AuditForm() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [auditReports, setAuditReports] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    date: "",
    scope: "",
    site: "",
    auditee: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    companyName: false,
    date: false,
    scope: false,
    site: false,
    auditee: false,
  });
  const handleDateChange = (name, date) => {
    setFormData((prevData) => ({ ...prevData, [name]: date }));
  };

  const fetchAuditReports = async (userData) => {
    try {
      const { company_name, userId } = userData;
      const auditsRef = db
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("Audits")
        .collection(userId);

      const auditsSnapshot = await auditsRef
        .orderBy("createdAt", "desc")
        .limit(10)
        .get();
      const audits = auditsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAuditReports(audits);
    } catch (error) {
      console.error("Error fetching audit reports:", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = await getUIDLocally();
        if (!userId) {
          throw new Error("User ID not found. Please log in again.");
        }
        const userDocRef = db
          .collection("peoples")
          .doc("users")
          .collection("users")
          .doc(userId);
        const userDoc = await userDocRef.get();
        if (userDoc.exists) {
          const userData = { ...userDoc.data(), userId };
          setUserData(userData);
          await fetchAuditReports(userData);
        } else {
          throw new Error(
            "User data not found. Please ensure you have the correct permissions."
          );
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoadError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, [fetchAuditReports]);


  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    })); setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: false, // Reset error for this field on input change
    }));
  };
  const validateForm = () => {
    const newErrors = {
      name: !formData.name,
      companyName: !formData.companyName,
      date: !formData.date,
      scope: !formData.scope,
      site: !formData.site,
      auditee: !formData.auditee,
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };
  const handleAuditStart = async () => {
    if (!validateForm()) {
      alert("Please fill in all required fields.");
      return;
    }

    const company_name = userData?.company_name || formData.companyName;
    const userId = userData?.userId;

    if (!company_name || !userId) {
      alert("Please fill in the required fields.");
      return;
    }

    setIsLoading(true);
    try {
      // Get the current audit_count
      const companyDocRef = db.collection("company_data").doc(company_name);
      const companyDoc = await companyDocRef.get();
      let currentAuditCount = companyDoc.data()?.audit_count || 0;

      // Increment the audit_count
      currentAuditCount++;

      // Update the audit_count in the company document
      await companyDocRef.set(
        { audit_count: currentAuditCount },
        { merge: true }
      );

      // Create a new audit document with the incremented audit_id
      const auditDocRef = db
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("Audits")
        .collection(userId)
        .doc(`audit_${currentAuditCount}`);

      await auditDocRef.set({
        audit_id: currentAuditCount,
        name: formData.name,
        company_name: company_name,
        date: formData.date,
        scope: formData.scope,
        site: formData.site,
        auditee: formData.auditee,
        isPending: true,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      setIsLoading(false);
      alert("Audit started successfully");
      navigate("/user/user/safety-questionnaire");
    } catch (error) {
      console.error("Error starting audit: ", error);
      setIsLoading(false);
      alert("Failed to start audit. Please try again.");
    }
    await fetchAuditReports(userData);
  };

  if (loadError) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography variant="h5" color="error" gutterBottom>
          Error Loading User Data
        </Typography>
        <Typography variant="body1" paragraph>
          {loadError}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/login")}
          sx={{ mt: 2 }}
        >
          Go to Login
        </Button>
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ mt: 2, p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Audit
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          align="center"
          display="block"
          sx={{ mt: 2,mb: 2 }}
        >
          Please fill the form to start audit
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Date"
                value={formData.date}
                onChange={(date) => handleDateChange("date", date)}
                renderInput={(params) => <TextField {...params} fullWidth error={errors.date}
                      helperText={errors.date ? "Date is required" : ""}/>}
              
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="name"
                label="Auditor Name"
                variant="outlined"
                required
                fullWidth
                value={formData.name}
                onChange={handleInputChange}
                error={errors.name}
                  helperText={errors.name ? "Auditor Name is required" : ""}

                  />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="companyName"
                label="Company Name"
                variant="outlined"
                required
                fullWidth
                value={formData.companyName}
                onChange={handleInputChange}
                // disabled={!!userData?.company_name}
                error={errors.companyName}
                  helperText={
                    errors.companyName ? "Company Name is required" : ""
                  }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="site"
                label="Audit Site Details"
                variant="outlined"
                required
                fullWidth
                value={formData.site}
                onChange={handleInputChange}
                error={errors.site}
                  helperText={errors.site ? "Audit Site Details are required" : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="auditee"
                label="Auditee Name"
                variant="outlined"
                fullWidth
                value={formData.auditee}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="scope"
                label="Scope of Audit"
                variant="outlined"
                multiline
                rows={3}
                fullWidth
                value={formData.scope}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={handleAuditStart}
                disabled={isLoading}
                sx={{ py: 1.5 }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Start Audit"}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Typography
          variant="caption"
          color="text.secondary"
          align="center"
          display="block"
          sx={{ mt: 2 }}
        >
          Your information is kept confidential and secure.
        </Typography>
      </Paper>
    </Container>
  </LocalizationProvider>
  );
}
