import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  CardContent,
  Button,
  Grid,
  Modal,
  Box,
  TextField,
  IconButton,
  Paper,
  CircularProgress,
  Alert,
  Tabs,
  Tab
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { firestore } from '../../firebase/firebaseConfig'; // Adjust the path as needed
import { useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import StatusCard from '../../componants/StatusCard';

const StyledCard = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  outline: 'none',
  maxHeight: '80vh',
  overflowY: 'auto',
}));

const WorkPermitList = () => {
  const [workPermits, setWorkPermits] = useState([]);
  const [selectedWorkPermit, setSelectedWorkPermit] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [noOfWorkPermitClosedByYou, setNoOfWorkPermitClosedByYou] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const [imageOfVerification, setImageOfVerification] = useState(null);

  const location = useLocation();
  const { company_name } = location.state || {};

  useEffect(() => {
    getWorkPermits();
  }, [company_name, isLoading]);

  const getWorkPermits = async () => {
    const workPermitsRef = collection(firestore, 'company_data', company_name, company_name, 'for_user', 'work_permit');
    const snapshot = await getDocs(workPermitsRef);
    const workPermitsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // // Sort work permits by date (newest first)
    // const sortedWorkPermits = workPermitsData.sort((a, b) => {
    //   return moment(b.date.seconds * 1000).diff(moment(a.date.seconds * 1000));
    // });

    // Sort work permits by case number (highest first)
     const sortedWorkPermits = workPermitsData.sort((a, b) => {
    // Assuming caseNumber is a string, we'll parse it to an integer
    const caseNumberA = parseInt(a.caseNumber, 10);
    const caseNumberB = parseInt(b.caseNumber, 10);
    return caseNumberB - caseNumberA; // For descending order
  });

    setWorkPermits(workPermitsData);
  };

  const handleRefresh = () => {
    setRefreshing(true);
    getWorkPermits().finally(() => setRefreshing(false));
  };

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  const handleModalOpen = (workPermit) => {
    setSelectedWorkPermit(workPermit);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedWorkPermit(null);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`work_permit_images/${Date.now()}_${file.name}`);

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image: ', error);
      throw error;
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfVerification(e.target.files[0]);
    }
  };

  const handleCloseWorkPermit = async () => {
    setIsLoading(true);
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }
    const remainingToBeClosed = (selectedWorkPermit.noOfWorkPermitComplied || 0) - (selectedWorkPermit.noOfWorkPermitClosed || 0);
    if (selectedWorkPermit.noOfWorkPermitClosed === selectedWorkPermit.noOfWorkPermitIssued) {
      setIsCompleted(true);
    }
    if (noOfWorkPermitClosedByYou <= remainingToBeClosed && noOfWorkPermitClosedByYou >= 0) {
      try {
        const workPermitRef = doc(firestore, 'company_data', company_name, company_name, 'for_user', 'work_permit', selectedWorkPermit.id);
        await updateDoc(workPermitRef, {
          isPending: !isCompleted,
          dateOfClosing: date,
          imageOfVerification: imageUrl,
          noOfWorkPermitClosed: (selectedWorkPermit.noOfWorkPermitClosed || 0) + noOfWorkPermitClosedByYou,
        });
        setSelectedWorkPermit(null);
        setNoOfWorkPermitClosedByYou(0);
        setIsLoading(false);
        alert('Work permit closed Successfully.');
        handleModalClose();
      } catch (error) {
        console.error('Error updating work permit closing:', error);
        setIsLoading(false);
      }
    } else {
      alert('Invalid value, No. of work permit closed can not be greater than remaining to be closed.');
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredWorkPermits = workPermits.filter(workPermit => 
    tabValue === 0 ? workPermit.isPending : !workPermit.isPending
  );

  return (
    <StyledPaper elevation={3}>
        <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Work Permits
      </Typography>
      {/* <Button onClick={handleRefresh} disabled={refreshing}>
        {refreshing ? 'Refreshing...' : 'Refresh'}
      </Button> */}

      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Pending" />
        <Tab label="Completed" />
      </Tabs>
      <Box mt={3} paddingBottom={4}>
        <Grid container spacing={2}>
          {filteredWorkPermits.map((workPermit) => (
            <Grid item xs={12} sm={6} md={4} key={workPermit.id}>
              <StyledCard elevation={1}>
                <CardContent>
                  <Typography><strong>Case Number:</strong> {workPermit.caseNumber}</Typography>
                  <Typography><strong>Date:</strong> {formatDate(workPermit.date)}</Typography>
                  <Typography><strong>Area Name:</strong> {workPermit.areaName}</Typography>
                  <Typography><strong>Description:</strong> {workPermit.description}</Typography>
                  <Typography color={workPermit.isPending ? 'error' : 'green'}><strong>Status:</strong> {workPermit.isPending ? 'Pending' : 'Closed'}</Typography>
                  <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={() => handleModalOpen(workPermit)}>
                      View Details
                    </Button>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>         
          ))}
        </Grid>
      </Box>


      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="work-permit-modal-title"
      >
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedWorkPermit && (
            <Box>
              <Typography id="work-permit-modal-title" variant="h6" gutterBottom>Work Permit Details</Typography>
              <Typography>Date: {formatDate(selectedWorkPermit.date)}</Typography>
              <Typography>Department Name: {selectedWorkPermit.departmentName}</Typography>
              <Typography>No. of Work Permits Issued: {selectedWorkPermit.noOfWorkPermitIssued}</Typography>
              <Typography>No. of Work Permits Closed: {selectedWorkPermit.noOfWorkPermitClosed}</Typography>
              <Typography>No. of Work Permits Inspected: {selectedWorkPermit.noOfWorkPermitInspected}</Typography>
              <Typography>No. of Work Permits Complied: {selectedWorkPermit.noOfWorkPermitComplied}</Typography>
              <Typography>Case Number: {selectedWorkPermit.caseNumber}</Typography>
              <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
                No. of Work Permits Remaining to be closed: {(selectedWorkPermit.noOfWorkPermitComplied || 0) - (selectedWorkPermit.noOfWorkPermitClosed || 0)}
              </Alert>
              {selectedWorkPermit.logRegisterImage && (
                <Box mt={2} mb={2}>
                  <img src={selectedWorkPermit.logRegisterImage} alt="Log Register" style={{ width: '100%', borderRadius: '4px' }} />
                </Box>
              )}
              {selectedWorkPermit.isPending && (
                <Box mt={2}>
                  <Typography>Date of Closing*</Typography>
                  <TextField
                    type="date"
                    value={moment(date).format('YYYY-MM-DD')}
                    onChange={handleDateChange}
                    fullWidth
                    margin="normal"
                    disabled={true}
                  />
                  <Typography>No. of Work Permit want to close:</Typography>
                  <TextField
                    type="number"
                    value={noOfWorkPermitClosedByYou.toString()}
                    onChange={(e) => setNoOfWorkPermitClosedByYou(parseInt(e.target.value, 10) || 0)}
                    fullWidth
                    margin="normal"
                    placeholder="Enter no. of work permit closed"
                    inputProps={{ min: 1 }}
                  />
                    <Grid item xs={12}>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                 // disabled={!canCloseFireInjury()}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload Image for Verification
                  </Button>
                </label>
                {imageOfVerification && <Typography>{imageOfVerification.name}</Typography>}
              </Grid>
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseWorkPermit}
                      disabled={isLoading}
                    >
                      {isLoading ? 'Closing...' : 'Close the form'}
                    </Button>
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                </Box>
              )}
              <Box mt={2}>
                <Button variant="outlined" onClick={handleModalClose}>
                  Back to list
                </Button>
              </Box>
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Container>
    </StyledPaper>
    
  );
};

export default WorkPermitList;