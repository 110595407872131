import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { BarChart, ExpandLess, ExpandMore, FormatAlignCenter, Home, Warning, Whatshot, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Avatar, Button, Collapse, ThemeProvider, Typography, styled, IconButton } from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getUIDLocally, setLoginStatusLocally, setUIDLocally } from "./../utils/userUtils";
import { useEffect, useState } from "react";
import { Analytics, Article, CalendarMonth, Engineering, HealthAndSafety, SafetyCheck, Task, TextSnippet } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import theme from "../theme";

const drawerWidth = 260;
const collapsedDrawerWidth = 90;

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: open ? drawerWidth : collapsedDrawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    backgroundColor: "#000000", // Darker blue background
    color: "#fff",
    width: open ? drawerWidth : collapsedDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
}));

// Routes definitions remain the same
// Routes

const chartroutes = [
  {
    path: "/user/user/unsafe-act-report-chart",
    label: <Typography variant="body2">Unsafe Act Report</Typography>,
    icon: <SafetyCheck style={{ fontSize: 20, color: "orange" }} />,
    permission: "Unsafe Act",
  },
  {
    path: "/user/user/near-miss-report-chart",
    label: <Typography variant="body2">Near Miss Report</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "yellow" }} />,
    permission: "Near Miss Report",
  },
  {
    path: "/user/user/fire-injury-report-chart",
    label: <Typography variant="body2">Fire Incident Report</Typography>,
    icon: <Whatshot style={{ fontSize: 20, color: "red" }} />,
    permission: "Fire Injury",
  },
  {
    path: "/user/user/accident-report-chart",
    label: <Typography variant="body2">Accident Report</Typography>,
    icon: <HealthAndSafety style={{ fontSize: 20, color: "yellow" }} />,
    permission: "Accident Report",
  },
  {
    path: "/user/user/work-permit-chart",
    label: <Typography variant="body2">Work Permit</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "lightblue" }} />,
    permission: "Work Permit",
  },
  {
    path: "/user/user/kaizen-report-chart",
    label: <Typography variant="body2">Kaizen Report</Typography>,
    icon: <BarChart style={{ fontSize: 20, color: "brown" }} />,
    permission: "Kaizen Reports",
  },
  {
    path: "/user/user/ppe-grievances-report-chart",
    label: <Typography variant="body2">PPE Grievances Report</Typography>,
    icon: <Engineering style={{ fontSize: 20, color: "green" }} />,
    permission: "PPE Grievances",
  },
  {
    path: "/user/user/weekly-safety-meeting-chart",
    label: <Typography variant="body2">Weekly Safety Meeting</Typography>,
    icon: <CalendarMonth style={{ fontSize: 20, color: "blue" }} />,
    permission: "Weekly Safety Meeting",
  },
];

const routes = [
  {
    path: "/user/user/unsafe-act-report",
    label: <Typography variant="body2">Unsafe Act Report</Typography>,
    icon: <SafetyCheck style={{ fontSize: 20, color: "orange" }} />,
    permission: "Unsafe Act",
  },
  {
    path: "/user/user/near-miss-report",
    label: <Typography variant="body2">Near Miss Report</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "yellow" }} />,
    permission: "Near Miss Report",
  },
  {
    path: "/user/user/fire-injury-report",
    label: <Typography variant="body2">Fire Incident Report</Typography>,
    icon: <Whatshot style={{ fontSize: 20, color: "red" }} />,
    permission: "Fire Injury",
  },
  {
    path: "/user/user/accident-report",
    label: <Typography variant="body2">Accident Report</Typography>,
    icon: <HealthAndSafety style={{ fontSize: 20, color: "yellow" }} />,
    permission: "Accident Report",
  },
  {
    path: "/user/user/work-permit",
    label: <Typography variant="body2">Work Permit</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "lightblue" }} />,
    permission: "Work Permit",
  },
  {
    path: "/user/user/kaizen-report",
    label: <Typography variant="body2">Kaizen Report</Typography>,
    icon: <BarChart style={{ fontSize: 20, color: "brown" }} />,
    permission: "Kaizen Reports",
  },
  {
    path: "/user/user/ppe-grievances-report",
    label: <Typography variant="body2">PPE Grievances Report</Typography>,
    icon: <Engineering style={{ fontSize: 20, color: "green" }} />,
    permission: "PPE Grievances",
  },
  {
    path: "/user/user/weekly-safety-meeting",
    label: <Typography variant="body2">Weekly Safety Meeting</Typography>,
    icon: <CalendarMonth style={{ fontSize: 20, color: "blue" }} />,
    permission: "Weekly Safety Meeting",
  },
];

// // Routes for Exame

const exameRoutes = [
  {
    path: "/user/user/exames",
    label: <Typography variant="body2">Training Exams</Typography>,
    icon: <Article style={{ fontSize: 20, color: "green" }} />,
  },
  {
    path: "/user/user/result",
    label: <Typography variant="body2">Exam result</Typography>,
    icon: <Article style={{ fontSize: 20, color: "green" }} />,
  },
];
// //added for managers

const managerRoutes = [
  {
    path: "/user/manager/unsafe-act-list",
    label: <Typography variant="body2">Unsafe Act List</Typography>,
    icon: <Engineering style={{ fontSize: 20, color: "green" }} />,
  },
  {
    path: "/user/manager/near-miss-list",
    label: <Typography variant="body2">Near Miss Report List</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "yellow" }} />,
  },
  {
    path: "/user/manager/fire-injury-list",
    label: <Typography variant="body2">Fire Injury List</Typography>,
    icon: <Whatshot style={{ fontSize: 20, color: "red" }} />,
  },
  {
    path: "/user/manager/accident-report-list",
    label: <Typography variant="body2">Accident Report List</Typography>,
    icon: <HealthAndSafety style={{ fontSize: 20, color: "yellow" }} />,
  },
  {
    path: "/user/manager/work-permit-list",
    label: <Typography variant="body2">Work Permit List</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "lightblue" }} />,
  },
  {
    path: "/user/manager/work-permit-auditor-list",
    label: <Typography variant="body2">Work Permit Audit</Typography>,
    icon: <TextSnippet style={{ fontSize: 20, color: "#008B8B" }} />,
  },
  {
    path: "/user/manager/kaizen-reports-list",
    label: <Typography variant="body2">Kaizen Report List</Typography>,
    icon: <BarChart style={{ fontSize: 20, color: "brown" }} />,
  },
  {
    path: "/user/manager/ppe-grievances-list",
    label: <Typography variant="body2">PPE Grievances List</Typography>,
    icon: <SafetyCheck style={{ fontSize: 20, color: "orange" }} />,
  },
];

 //Add audit routes

 const auditRoutes = [
  {
    path: "/user/user/audit",
    label: <Typography variant="body2">Audit Form</Typography>,
    icon: <Article style={{ fontSize: 20, color: "green" }} />,
  },
  {
    path: "/user/user/audit-report",
    label: <Typography variant="body2">audit Report List</Typography>,
    icon: <Warning style={{ fontSize: 20, color: "yellow" }} />,
  },
]

const ListItemStyled = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: "4px 8px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.08)",
  },
}));

export default function UserDrawer() {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [openForm, setOpenForm] = useState(false);
  const [openManager, setOpenManager] = useState(false);
  const [openCharts, setOpenCharts] = useState(false);
  const [auditOpen, setAuditOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [exameOpen, setExameOpen] = useState(false);

  const handleDrawerToggle = () => setOpen(!open);
  const handleClickAudit = () => setAuditOpen(!auditOpen);
  const handleClickForm = () => setOpenForm(!openForm);
  const handleClickManager = () => setOpenManager(!openManager);
  const handleClickChart = () => setOpenCharts(!openCharts);
  const handleDashboard = () => navigate("/user/user");
  const handleExame = () => setExameOpen(!exameOpen);

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      setLoginStatusLocally(false);
      setUIDLocally(null);
      setUserData(null);
      window.location.href = "/";
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  var cnt = 0;
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = await getUIDLocally();
        const userDocRef = firebase
          .firestore()
          .collection("peoples")
          .doc("users")
          .collection("users")
          .doc(userId);
        const userDoc = await userDocRef.get();
        console.log('fetchuserData ' ,cnt++);

        if (userDoc.exists) {
          setUserData({ ...userDoc.data(), userId });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, );

  if (!userData) {
    return null;
  }

  const { company_name, email, permissions, role, user_name } = userData;

  const renderListItems = (items, parentOpen = true) => {
    return items.map((route) => {
      if (!route.permission || permissions.includes(route.permission)) {
        return (
          <ListItem key={route.path} disablePadding sx={{ display: parentOpen ? 'block' : 'none' }}>
            <ListItemStyled onClick={() => navigate(route.path)}>
              <ListItemIcon sx={{ color: "inherit", minWidth: 40 }}>
                {route.icon}
              </ListItemIcon>
              <ListItemText primary={route.label} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemStyled>
          </ListItem>
        );
      }
      return null;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <CustomDrawer variant="permanent" open={open}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", p: 1 }}>
            <IconButton onClick={handleDrawerToggle} sx={{ color: "white" }}>
              {open ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </Box>
          <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.12)' }} />
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", my: 2 ,mx:0, paddingRight: 1 }}>
            <Avatar alt={user_name} src="/path/to/profile-picture.jpg" 
                    sx={{ width: open ? 60 : 40, height: open ? 60 : 40, mb: 1 }} />
            {open && (
              <Box textAlign="center">
                <Typography variant="subtitle1">{user_name}</Typography>
                <Typography variant="body2">{email}</Typography>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Role: {role}
                </Typography>
              </Box>
            )}
          </Box>
          <Divider sx={{ backgroundColor: 'rgba(255,255,255,0.12)' }} />
          <List >
            <ListItemStyled onClick={handleDashboard}>
              <ListItemIcon sx={{ color: "inherit", minWidth: 40 }}>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemStyled>
            <Collapse in={openCharts} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderListItems(chartroutes, open)}
              </List>
            </Collapse>

            <ListItemStyled onClick={handleClickForm}>
              <ListItemIcon sx={{ color: "inherit", minWidth: 40 }}>
                <FormatAlignCenter />
              </ListItemIcon>
              <ListItemText primary="Forms" sx={{ opacity: open ? 1 : 0 }} />
              {open && (openForm ? <ExpandLess /> : <ExpandMore />)}
            </ListItemStyled>
            <Collapse in={openForm} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderListItems(routes, open)}
              </List>
            </Collapse>

            {role === "manager" && (
              <>
                <ListItemStyled onClick={handleClickManager}>
                  <ListItemIcon sx={{ color: "inherit", minWidth: 40 }}>
                    <TextSnippet />
                  </ListItemIcon>
                  <ListItemText primary="Reports" sx={{ opacity: open ? 1 : 0 }} />
                  {open && (openManager ? <ExpandLess /> : <ExpandMore />)}
                </ListItemStyled>
                <Collapse in={openManager} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {renderListItems(managerRoutes, open)}
                  </List>
                </Collapse>
              </>
            )}

            <ListItemStyled onClick={handleClickAudit}>
              <ListItemIcon sx={{ color: "inherit", minWidth: 40 }}>
                <Task />
              </ListItemIcon>
              <ListItemText primary="Audit" sx={{ opacity: open ? 1 : 0 }} />
              {open && (auditOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItemStyled>
            <Collapse in={auditOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderListItems(auditRoutes, open)}
              </List>
            </Collapse>
            <ListItemStyled onClick={handleExame}>
              <ListItemIcon sx={{ color: "inherit", minWidth: 40 }}>
                <Task />
              </ListItemIcon>
              <ListItemText primary="Training Exame" sx={{ opacity: open ? 1 : 0 }} />
              {open && (exameOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItemStyled>
            <Collapse in={exameOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderListItems(exameRoutes, open)}
              </List>
            </Collapse>
          </List>
          <Box sx={{ mt: 'auto', p: 2, textAlign: "center" }}>
            <Button
              onClick={handleLogout}
              variant="contained"
              color="error"
              fullWidth={open}
              sx={{
                minWidth: open ? "auto" : "40px",
                p: open ? "8px 16px" : "8px",
              }}
            >
              {open ? "Logout" : "Logout"}
            </Button>
            {open && (
              <Typography variant="caption" component="p" sx={{ mt: 2, color: "rgba(255,255,255,0.7)" }}>
                &copy; {new Date().getFullYear()} {company_name}. All rights reserved.
              </Typography>
            )}
          </Box>
        </CustomDrawer>
      </Box>
    </ThemeProvider>
  );
}
