// import React, { useState, useEffect } from 'react';
// import { 
//   Typography, 
//   TextField, 
//   Button, 
//   FormControl, 
//   InputLabel, 
//   Select, 
//   MenuItem, 
//   Grid, 
//   Paper, 
//   CircularProgress, 
//   FormControlLabel, 
//   Checkbox,
//   Tabs,
//   Tab,
//   Card,
//   CardContent,
//   Box 
// } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import { DatePicker } from '@material-ui/pickers';
// import {firestore } from '../../firebase/firebaseConfig';
// import firebase from 'firebase/compat/app';
// import moment from 'moment';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(3),
//     maxHeight: '100vh',
//     overflowY: 'auto',
//   },
//   paper: {
//     padding: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//   },
//   title: {
//     marginBottom: theme.spacing(2),
//   },
//   form: {
//     '& > *': {
//       marginBottom: theme.spacing(2),
//     },
//   },
//   submitButton: {
//     marginRight: theme.spacing(2),
//   },
//   error: {
//     color: theme.palette.error.main,
//   },
//   imageInput: {
//     display: 'none',
//   },
//   reportsSection: {
//     marginTop: theme.spacing(4),
//   },
//   reportCard: {
//     marginBottom: theme.spacing(2),
//   },
// }));

// const UnsafeActReport = ({ userId, email, company_name }) => {
//   const classes = useStyles();
//   const [date, setDate] = useState(new Date());
//   const [auditorName, setAuditorName] = useState('');
//   const [employeeId, setEmployeeId] = useState('');
//   const [reportingPersonDesignation, setReportingPersonDesignation] = useState('');
//   const [typeOfObservation, setTypeOfObservation] = useState('');
//   const [description, setDescription] = useState('');
//   const [image, setImage] = useState(null);
//   const [severity, setSeverity] = useState('');
//   const [immediateActionRequired, setImmediateActionRequired] = useState(false);
//   const [departmentName, setDepartmentName] = useState('');
//   const [areaName, setAreaName] = useState('');
//   const [responsiblePerson, setResponsiblePerson] = useState('');
//   const [responsibleDepartment, setResponsibleDepartment] = useState('');
//   const [plannedDateOfClosing, setPlannedDateOfClosing] = useState(new Date());

//   const [departments, setDepartments] = useState([]);
//   const [areas, setAreas] = useState([]);
//   const [rpDesignation, setrpDesignation] = useState([]);
//   const [rDept, setRDept] = useState([]);
//   const [errors, setErrors] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const [caseCount, setCaseCount] = useState(0);

//   const [tabValue, setTabValue] = useState(0);
//   const [reports, setReports] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {

//       const companyRef = firestore.collection('company_data').doc(company_name);
//       // Fetch departments from Firestore
//       const departmentsSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_admin')
//         .collection('department_name')
//         .get();
//       const departmentData = departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setDepartments(departmentData);

//       // Fetch areas from Firestore
//       const areasSnapshot = await firestore
//         .collection('company_data')
//         .doc(company_name)
//         .collection(company_name)
//         .doc('for_admin')
//         .collection('company_zones')
//         .get();
//       const areaData = areasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setAreas(areaData);

//       //fetch reporting person designation 
//   const designationSnapshot = await firestore
//   .collection('company_data')
//   .doc(company_name)
//   .collection(company_name)
//   .doc('for_admin')
//   .collection('reporting_person_designation').get();
//     const rpdData = designationSnapshot.docs.map((doc) => ({ id: doc.id,
//       name: doc.data().name, // Accessing the name field using doc.data().name
//      }));
//     setrpDesignation(rpdData);

//     //fetch responsible department
//     const deptSnapshot = await firestore
//     .collection('company_data')
//     .doc(company_name)
//     .collection(company_name)
//     .doc('for_admin')
//     .collection('responsible_department')
//     .get();
//       const dData = deptSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//       setRDept(dData);

//       // Fetch case count
//       const companyDoc = await companyRef.get();
//       if (companyDoc.exists) {
//         setCaseCount(companyDoc.data().case_count || 0);
//       }
//       // Fetch user reports
//       fetchReports();

//     };

//     fetchData();
//   }, [company_name,userId]);

//   const fetchReports = async () => {
//     const reportsSnapshot = await firestore
//       .collection('company_data')
//       .doc(company_name)
//       .collection(company_name)
//       .doc('for_user')
//       .collection('unsafe_act')
//       .where('userId', '==', userId)
//       .get();

//     const reportsData = reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//     setReports(reportsData);
//   };

//   const formatDate = (timestamp) => {
//     if (typeof timestamp === 'string' && timestamp.includes('-')) {
//       return timestamp;
//     }
//     if (timestamp && timestamp.seconds) {
//       return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
//     }
//     return 'Invalid Date';
//   };


//   const validate = () => {
//     const errors = {};
//     if (!date) errors.date = 'Date is required';
//     if (!auditorName) errors.auditorName = 'Auditor Name is required';
//     if (!employeeId) errors.employeeId = 'Employee ID is required';
//     if (!reportingPersonDesignation) errors.reportingPersonDesignation = 'Reporting Person Designation is required';
//     if (!typeOfObservation) errors.typeOfObservation = 'Type of Observation is required';
//     if (!description) errors.description = 'Description is required';
//     if (!severity) errors.severity = 'Severity is required';
//     if (!departmentName) errors.departmentName = 'Department Name is required';
//     if (!areaName) errors.areaName = 'Area Name is required';
//     if (!responsiblePerson) errors.responsiblePerson = 'Responsible Person is required';
//     if (!responsibleDepartment) errors.responsibleDepartment = 'Responsible Department is required';
//     if (!plannedDateOfClosing) errors.plannedDateOfClosing = 'Planned Date of Closing is required';
//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const uploadImage = async (file) => {
//     if (!file) return null;

//     const storageRef = firebase.storage().ref();
//     const fileRef = storageRef.child(`images/${company_name}/${new Date().toISOString()}`);

//     try {
//       await fileRef.put(file);
//       const downloadURL = await fileRef.getDownloadURL();
//       return downloadURL;
//     } catch (error) {
//       console.error('Error uploading image: ', error);
//       throw error;
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsLoading(true);
//       try {
//         const caseNumber = caseCount + 1;

//         let imageUrl = null;
//         if (image) {
//           imageUrl = await uploadImage(image);
//         }
//         const docRef = await firestore
//           .collection('company_data')
//           .doc(company_name)
//           .collection(company_name)
//           .doc('for_user')
//           .collection('unsafe_act')
//           .add({
//             date,
//             auditorName,
//             employeeId,
//             reportingPersonDesignation,
//             typeOfObservation,
//             description,
//             image : imageUrl,
//             severity,
//             immediateActionRequired,
//             departmentName,
//             areaName,
//             responsiblePerson,
//             responsibleDepartment,
//             plannedDateOfClosing,
//             caseNumber,
//             userId,
//             isPending: true,
//           });
        
//              // Update the case count
//       await firestore.collection('company_data').doc(company_name)
//       .update({
//         case_count: caseNumber,
//       });
//       const detailRef = firestore
//       .collection('company_data')
//       .doc(company_name)
//       .collection(company_name)
//       .doc('for_user')
//       .collection('unsafe_act')
//       .doc('details');

//     const detailRefDoc = await detailRef.get();

//     if (detailRefDoc.exists) {
//       await detailRef.delete();
//     }
//         console.log('Document written with ID: ', docRef.id);

//         clearForm();
//         setIsLoading(false);
//         alert('Form submitted successfully');
//       } catch (error) {
//         console.error('Error submitting form:', error);
//         setIsLoading(false);
//         alert('Failed to submit form');
//       }
//     }
//   };

//   const handleImageChange = (e) => {
//     if (e.target.files.length > 0) {
//       setImage(e.target.files[0]);
//     }
//   };

//   const clearForm = () => {
//     setDate(new Date());
//     setAuditorName('');
//     setEmployeeId('');
//     setReportingPersonDesignation('');
//     setTypeOfObservation('');
//     setDescription('');
//     setImage(null);
//     setSeverity('');
//     setImmediateActionRequired(false);
//     setDepartmentName('');
//     setAreaName('');
//     setResponsiblePerson('');
//     setResponsibleDepartment('');
//     setPlannedDateOfClosing(new Date());
//     setErrors({});
//   };
//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   return (
//     <div className={classes.root}>
//       <Paper className={classes.paper}>
//         <Typography variant="h4" className={classes.title}>
//           Unsafe Act/Unsafe Condition Reports
//         </Typography>
//         <form onSubmit={handleSubmit} className={classes.form}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Date"
//                 value={date.toString()}
//                 onChange={setDate}
//                 fullWidth
//                 error={!!errors.date}
//                 helperText={errors.date}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Auditor Name"
//                 value={auditorName}
//                 onChange={(e) => setAuditorName(e.target.value)}
//                 fullWidth
//                 error={!!errors.auditorName}
//                 helperText={errors.auditorName}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Employee ID"
//                 value={employeeId}
//                 onChange={(e) => setEmployeeId(e.target.value)}
//                 fullWidth
//                 error={!!errors.employeeId}
//                 helperText={errors.employeeId}
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.areaName}>
//                 <InputLabel>Reporting Person Designation</InputLabel>
//                 <Select
//                   value={reportingPersonDesignation}
//                   onChange={(e) => setReportingPersonDesignation(e.target.value)}
//                 >
//                   {rpDesignation.map((rpd) => (
//                     <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
//                   ))}
//                 </Select>
//                 {errors.reportingPersonDesignation && <Typography className={classes.error}>{errors.reportingPersonDesignation}</Typography>}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.typeOfObservation}>
//                 <InputLabel>Type of Observation*</InputLabel>
//                 <Select
//                   value={typeOfObservation}
//                   onChange={(e) => setTypeOfObservation(e.target.value)}
//                 >
//                   <MenuItem value="Unsafe Act">Unsafe Act</MenuItem>
//                   <MenuItem value="Unsafe Condition">Unsafe Condition</MenuItem>
//                   {/* <MenuItem value="high">High</MenuItem> */}
//                 </Select>
//                 {errors.typeOfObservation && <Typography className={classes.error}>{errors.typeOfObservation}</Typography>}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 label="Description of observation*"
//                 value={description}
//                 onChange={(e) => setDescription(e.target.value)}
//                 fullWidth
//                 multiline
//                 rows={4}
//                 error={!!errors.description}
//                 helperText={errors.description}
//               />
//             </Grid>
//             <Grid item xs={12} >
//               <input
//                 accept="image/*"
//                 className={classes.imageInput}
//                 id="contained-button-file"
//                 type="file"
//                 onChange={handleImageChange}
//               />
//               <label htmlFor="contained-button-file">
//                 <Button variant="contained" color="primary" component="span">
//                   Upload Image of Observation
//                 </Button>
//               </label>
//               {image && <Typography>{image.name}</Typography>}
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.severity}>
//                 <InputLabel>Severity</InputLabel>
//                 <Select
//                   value={severity}
//                   onChange={(e) => setSeverity(e.target.value)}
//                 >
//                   <MenuItem value="Low">Low</MenuItem>
//                   <MenuItem value="Medium">Medium</MenuItem>
//                   <MenuItem value="High">High</MenuItem>
//                 </Select>
//                 {errors.severity && <Typography className={classes.error}>{errors.severity}</Typography>}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={immediateActionRequired}
//                     onChange={(e) => setImmediateActionRequired(e.target.checked)}
//                   />
//                 }
//                 label="Immediate Action Required"
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.departmentName}>
//                 <InputLabel>Department Name</InputLabel>
//                 <Select
//                   value={departmentName}
//                   onChange={(e) => setDepartmentName(e.target.value)}
//                 >
//                   {departments.map((dept) => (
//                     <MenuItem key={dept.id} value={dept.name}>{dept.name}</MenuItem>
//                   ))}
//                 </Select>
//                 {errors.departmentName && <Typography className={classes.error}>{errors.departmentName}</Typography>}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.areaName}>
//                 <InputLabel>Area Name</InputLabel>
//                 <Select
//                   value={areaName}
//                   onChange={(e) => setAreaName(e.target.value)}
//                 >
//                   {areas.map((area) => (
//                     <MenuItem key={area.id} value={area.name}>{area.name}</MenuItem>
//                   ))}
//                 </Select>
//                 {errors.areaName && <Typography className={classes.error}>{errors.areaName}</Typography>}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Responsible Person"
//                 value={responsiblePerson}
//                 onChange={(e) => setResponsiblePerson(e.target.value)}
//                 fullWidth
//                 error={!!errors.responsiblePerson}
//                 helperText={errors.responsiblePerson}
//               />
//             </Grid>
//             {/* <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Responsible Department"
//                 value={responsibleDepartment}
//                 onChange={(e) => setResponsibleDepartment(e.target.value)}
//                 fullWidth
//                 error={!!errors.responsibleDepartment}
//                 helperText={errors.responsibleDepartment}
//               />
//             </Grid> */}
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth error={!!errors.responsibleDepartment}>
//                 <InputLabel>Responsible Department</InputLabel>
//                 <Select
//                   value={responsibleDepartment}
//                   onChange={(e) => setResponsibleDepartment(e.target.value)}
//                 >
//                   {rDept.map((rpd) => (
//                     <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
//                   ))}
//                 </Select>
//                 {errors.responsibleDepartment && <Typography className={classes.error}>{errors.responsibleDepartment}</Typography>}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <DatePicker
//                 label="Planned Date of Closing"
//                 value={plannedDateOfClosing}
//                 onChange={setPlannedDateOfClosing}
//                 fullWidth
//                 error={!!errors.plannedDateOfClosing}
//                 helperText={errors.plannedDateOfClosing}
//               />
//             </Grid>
          
//           </Grid>
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             className={classes.submitButton}
//             disabled={isLoading}
//           >
//             {isLoading ? <CircularProgress size={24} /> : 'Submit'}
//           </Button>
//           <Button variant="outlined" onClick={clearForm}>
//             Clear
//           </Button>
//         </form>
//       </Paper>
//       <div className={classes.reportsSection}>
//         <Typography variant="h5" className={classes.title}>
//           Your Reports
//         </Typography>
//         <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
//           <Tab label="Pending" />
//           <Tab label="Completed" />
//         </Tabs>
//         <Box mt={2}>
//           {reports
//             .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
//             .map(report => (
//               <Card key={report.id} className={classes.reportCard}>
//                 <CardContent>
//                   <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
//                   <Typography>Date: {formatDate(report.date)} </Typography>
//                   <Typography>Description: {report.description}</Typography>
//                 </CardContent>
//               </Card>
//             ))
//           }
//         </Box>
//       </div>
//     </div>
//   );
// };

// export default UnsafeActReport;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Card,
  CardContent,
  Box,
  Container,
  Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { firestore } from '../../firebase/firebaseConfig';
import firebase from 'firebase/compat/app';
import moment from 'moment';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const UnsafeActReport = ({ userId, email, company_name }) => {
  const [formData, setFormData] = useState({
    date: new Date(),
    auditorName: '',
    employeeId: '',
    reportingPersonDesignation: '',
    typeOfObservation: '',
    description: '',
    image: null,
    severity: '',
    immediateActionRequired: false,
    departmentName: '',
    areaName: '',
    responsiblePerson: '',
    responsibleDepartment: '',
    plannedDateOfClosing: new Date(),
  });

  const [departments, setDepartments] = useState([]);
  const [areas, setAreas] = useState([]);
  const [rpDesignation, setrpDesignation] = useState([]);
  const [rDept, setRDept] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [caseCount, setCaseCount] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [reports, setReports] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const companyRef = firestore.collection('company_data').doc(company_name);
      
      // Fetch departments
      const departmentsSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('department_name')
        .get();
      setDepartments(departmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch areas
      const areasSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('company_zones')
        .get();
      setAreas(areasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch reporting person designations
      const designationSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('reporting_person_designation')
        .get();
      setrpDesignation(designationSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name })));

      // Fetch responsible departments
      const deptSnapshot = await companyRef
        .collection(company_name)
        .doc('for_admin')
        .collection('responsible_department')
        .get();
      setRDept(deptSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch case count
      const companyDoc = await companyRef.get();
      if (companyDoc.exists) {
        setCaseCount(companyDoc.data().case_count || 0);
      }

      // Fetch user reports
      fetchReports();
    };

    fetchData();
  }, [company_name, userId]);

  const fetchReports = async () => {
    const reportsSnapshot = await firestore
      .collection('company_data')
      .doc(company_name)
      .collection(company_name)
      .doc('for_user')
      .collection('unsafe_act')
      .where('userId', '==', userId)
      .get();

    setReports(reportsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setFormData(prevData => ({ ...prevData, [name]: date }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: checked }));
  };

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      setFormData(prevData => ({ ...prevData, image: event.target.files[0] }));
    }
  };

  const validate = () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      if (!formData[key] && key !== 'image' && key !== 'immediateActionRequired') {
        newErrors[key] = 'This field is required';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadImage = async (file) => {
    if (!file) return null;
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`images/${company_name}/${new Date().toISOString()}`);
    try {
      await fileRef.put(file);
      return await fileRef.getDownloadURL();
    } catch (error) {
      console.error('Error uploading image: ', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        const caseNumber = caseCount + 1;
        let imageUrl = null;
        if (formData.image) {
          imageUrl = await uploadImage(formData.image);
        }
        
        await firestore
          .collection('company_data')
          .doc(company_name)
          .collection(company_name)
          .doc('for_user')
          .collection('unsafe_act')
          .add({
            ...formData,
            image: imageUrl,
            caseNumber,
            userId,
            isPending: true,
          });

        await firestore.collection('company_data').doc(company_name)
          .update({
            case_count: caseNumber,
          });

        setFormData({
          date: new Date(),
          auditorName: '',
          employeeId: '',
          reportingPersonDesignation: '',
          typeOfObservation: '',
          description: '',
          image: null,
          severity: '',
          immediateActionRequired: false,
          departmentName: '',
          areaName: '',
          responsiblePerson: '',
          responsibleDepartment: '',
          plannedDateOfClosing: new Date(),
        });
        setErrors({});
        setIsLoading(false);
        setSnackbarMessage('Form submitted successfully');
        setSnackbarOpen(true);
        fetchReports();
      } catch (error) {
        console.error('Error submitting form:', error);
        setIsLoading(false);
        setSnackbarMessage('Failed to submit form');
        setSnackbarOpen(true);
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg">
        <StyledPaper elevation={3}>
          <Typography variant="h4" gutterBottom>
            Unsafe Act/Unsafe Condition Report
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Date"
                  value={formData.date}
                  onChange={(date) => handleDateChange('date', date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Auditor Name"
                  name="auditorName"
                  value={formData.auditorName}
                  onChange={handleChange}
                  error={!!errors.auditorName}
                  helperText={errors.auditorName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Employee ID"
                  name="employeeId"
                  value={formData.employeeId}
                  onChange={handleChange}
                  error={!!errors.employeeId}
                  helperText={errors.employeeId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.reportingPersonDesignation}>
                  <InputLabel>Reporting Person Designation</InputLabel>
                  <Select
                    name="reportingPersonDesignation"
                    value={formData.reportingPersonDesignation}
                    onChange={handleChange}
                  >
                    {rpDesignation.map((rpd) => (
                      <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.typeOfObservation}>
                  <InputLabel>Type of Observation</InputLabel>
                  <Select
                    name="typeOfObservation"
                    value={formData.typeOfObservation}
                    onChange={handleChange}
                  >
                    <MenuItem value="Unsafe Act">Unsafe Act</MenuItem>
                    <MenuItem value="Unsafe Condition">Unsafe Condition</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description of observation"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageChange}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload Image of Observation
                  </Button>
                </label>
                {formData.image && <Typography variant="body2">{formData.image.name}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.severity}>
                  <InputLabel>Severity</InputLabel>
                  <Select
                    name="severity"
                    value={formData.severity}
                    onChange={handleChange}
                  >
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.immediateActionRequired}
                      onChange={handleCheckboxChange}
                      name="immediateActionRequired"
                    />
                  }
                  label="Immediate Action Required"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.departmentName}>
                  <InputLabel>Department Name</InputLabel>
                  <Select
                    name="departmentName"
                    value={formData.departmentName}
                    onChange={handleChange}
                  >
                    {departments.map((dept) => (
                      <MenuItem key={dept.id} value={dept.name}>{dept.name}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.areaName}>
                  <InputLabel>Area Name</InputLabel>
                  <Select
                    name="areaName"
                    value={formData.areaName}
                    onChange={handleChange}
                  >
                    {areas.map((area) => (
                      <MenuItem key={area.id} value={area.name}>{area.name}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Responsible Person"
                  name="responsiblePerson"
                  value={formData.responsiblePerson}
                  onChange={handleChange}
                  error={!!errors.responsiblePerson}
                  helperText={errors.responsiblePerson}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledFormControl fullWidth error={!!errors.responsibleDepartment}>
                  <InputLabel>Responsible Department</InputLabel>
                  <Select
                    name="responsibleDepartment"
                    value={formData.responsibleDepartment}
                    onChange={handleChange}
                  >
                    {rDept.map((rpd) => (
                      <MenuItem key={rpd.id} value={rpd.name}>{rpd.name}</MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Planned Date of Closing"
                  value={formData.plannedDateOfClosing}
                  onChange={(date) => handleDateChange('plannedDateOfClosing', date)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!errors.plannedDateOfClosing}
                      helperText={errors.plannedDateOfClosing}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box mt={3}>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </StyledButton>
              <Button
                variant="outlined"
                onClick={() => {
                  setFormData({
                    date: new Date(),
                    auditorName: '',
                    employeeId: '',
                    reportingPersonDesignation: '',
                    typeOfObservation: '',
                    description: '',
                    image: null,
                    severity: '',
                    immediateActionRequired: false,
                    departmentName: '',
                    areaName: '',
                    responsiblePerson: '',
                    responsibleDepartment: '',
                    plannedDateOfClosing: new Date(),
                  });
                  setErrors({});
                }}
              >
                Clear
              </Button>
            </Box>
          </form>
        </StyledPaper>

        <StyledPaper elevation={3}>
          <Typography variant="h5" gutterBottom>
            Unsafe Act Reports
          </Typography>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box mt={3}>
          <Grid container spacing={2}>
          {reports
              .filter(report => tabValue === 0 ? report.isPending : !report.isPending)
              .map(report => (
                <Grid item xs={12} sm={6} md={4} key={report.id}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6">Case Number: {report.caseNumber}</Typography>
                    <Typography>Date: {formatDate(report.date)}</Typography>
                    <Typography>Description: {report.description}</Typography>
                    <Typography color={report.isPending ? 'error' : 'green'} >Status: {report.isPending ? 'Pending' : 'Completed'}</Typography>
                  </CardContent>
                </StyledCard>
                </Grid>
              ))}
          </Grid>
          </Box>
        </StyledPaper>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </LocalizationProvider>
  );
};

export default UnsafeActReport;
