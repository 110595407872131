// import React, { useState, useEffect } from "react";
// import { useTheme, useMediaQuery } from "@mui/material";
// import {
//   Box,
//   Typography,
//   Paper,
//   Grid,
//   Card,
//   CardContent,
//   Chip,
//   Button,
//   Divider,
//   Modal,
//   IconButton,
//   TextField,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   CircularProgress,
//   Tabs,
//   Tab, Container,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import {
//   PendingActions,
//   Info,
//   PriorityHigh,
//   Close,
//   Add as AddIcon,
//   Delete as DeleteIcon,
//   Warning,
//   CheckCircle,
// } from "@mui/icons-material";
// import CloseIcon from "@mui/icons-material/Close";
// //import { PendingActions, PriorityHigh, Close } from '@mui/icons-material';
// import { firestore } from "../../firebase/firebaseConfig";
// import { useLocation } from "react-router-dom";
// import firebase from "firebase/compat/app";
// import "firebase/compat/storage";
// import moment from "moment";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { format } from "date-fns";

// const StyledCard = styled(Card)(({ theme }) => ({
//   marginBottom: theme.spacing(2),
//   transition: "all 0.3s ease-in-out",
//   "&:hover": {
//     transform: "translateY(-5px)",
//     boxShadow: theme.shadows[4],
//   },
// }));

// const StyledPaper = styled(Card)(({ theme }) => ({
//   marginBottom: theme.spacing(2),
// }));

// const ModalContent = styled(Box)(({ theme }) => ({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "90%",
//   maxWidth: 800,
//   backgroundColor: theme.palette.background.paper,
//   boxShadow: theme.shadows[5],
//   padding: theme.spacing(4),
//   outline: "none",
//   maxHeight: "80vh",
//   overflowY: "auto",
//   borderRadius: theme.shape.borderRadius,
// }));

// const ActionPlanForm = styled(Box)(({ theme }) => ({
//   borderRadius: theme.shape.borderRadius,
//   padding: theme.spacing(2),
//   marginBottom: theme.spacing(2),
//   backgroundColor: theme.palette.grey[50],
// }));

// const NearMissReportList = () => {
//   // ... (all state variables and functions remain the same)

//   const [reports, setReports] = useState([]);
//   const [selectedReport, setSelectedReport] = useState(null);
//   const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
//   const [isFormModalOpen, setIsFormModalOpen] = useState(false);
//   const [isInvestigationModalOpen, setIsInvestigationModalOpen] =
//     useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [date, setDate] = useState(new Date());
//   const [tabValue, setTabValue] = useState(0);
//   const [imageOfVerification, setImageOfVerification] = useState(null);
//   const location = useLocation();
//   const { company_name } = location.state || {};

//   const [investigationTeam, setInvestigationTeam] = useState("");
//   const [dateOfInvestigation, setDateOfInvestigation] = useState(new Date());
//   const [timeOfInvestigation, setTimeOfInvestigation] = useState(new Date());
//   const [rootCause, setRootCause] = useState("");
//   const [actionPlans, setActionPlans] = useState([]);
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [expanded, setExpanded] = useState(false);

//   useEffect(() => {
//     const fetchReports = async () => {
//       const reportsRef = firestore
//         .collection("company_data")
//         .doc(company_name)
//         .collection(company_name)
//         .doc("for_user")
//         .collection("near_miss_report");
//       const snapshot = await reportsRef.get();
//       const reportsData = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));

//       const sortedReportsData = reportsData.sort((a, b) => {
//         const caseNumberA = parseInt(a.caseNumber, 10);
//         const caseNumberB = parseInt(b.caseNumber, 10);
//         return caseNumberB - caseNumberA;
//       });

//       setReports(sortedReportsData);
//     };
//     fetchReports();
//   }, [company_name]);

//   const formatDate = (timestamp) => {
//     if (typeof timestamp === "string" && timestamp.includes("-")) {
//       return timestamp;
//     }
//     if (timestamp && timestamp.seconds) {
//       return moment(
//         timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
//       ).format("MMMM D, YYYY");
//     }
//     return "Invalid Date";
//   };

//   const handleDetailsModalOpen = (report) => {
//     setSelectedReport(report);
//     setIsDetailsModalOpen(true);
//   };

//   const handleFormModalOpen = (report) => {
//     setSelectedReport(report);
//     setIsFormModalOpen(true);
//   };

//   const handleInvestigationModalOpen = (report) => {
//     setSelectedReport(report);
//     setIsInvestigationModalOpen(true);
//     if (report.DateOfInvestigation) {
//       setInvestigationTeam(report.InvestigationTeam || "");
//       setDateOfInvestigation(report.DateOfInvestigation.toDate());
//       setTimeOfInvestigation(report.TimeOfInvestigation.toDate());
//       setRootCause(report.rootCause || "");
//       setActionPlans(report.actionPlans || []);
//       setIsEditMode(true);
//     } else {
//       setInvestigationTeam("");
//       setDateOfInvestigation(new Date());
//       setTimeOfInvestigation(new Date());
//       setRootCause("");
//       setActionPlans([]);
//       setIsEditMode(false);
//     }
//   };

//   const handleModalClose = () => {
//     setIsDetailsModalOpen(false);
//     setIsFormModalOpen(false);
//     setIsInvestigationModalOpen(false);
//     setSelectedReport(null);
//   };

//   const handleDateChange = (newDate) => {
//     setDate(newDate);
//   };

//   const uploadImage = async (file) => {
//     if (!file) return null;

//     const storageRef = firebase.storage().ref();
//     const fileRef = storageRef.child(
//       `near_miss_report_images/${Date.now()}_${file.name}`
//     );

//     try {
//       await fileRef.put(file);
//       const downloadURL = await fileRef.getDownloadURL();
//       return downloadURL;
//     } catch (error) {
//       console.error("Error uploading image: ", error);
//       throw error;
//     }
//   };

//   const handleCloseNearMissReport = async () => {
//     setIsLoading(true);
//     let imageUrl = null;
//     if (imageOfVerification) {
//       imageUrl = await uploadImage(imageOfVerification);
//     }
//     try {
//       const nearMissReportRef = firestore
//         .collection("company_data")
//         .doc(company_name)
//         .collection(company_name)
//         .doc("for_user")
//         .collection("near_miss_report")
//         .doc(selectedReport.id);
//       await nearMissReportRef.update({
//         isPending: false,
//         dateOfClosing: date,
//         imageOfVerification: imageUrl,
//       });
//       setIsLoading(false);
//       handleModalClose();
//     } catch (error) {
//       console.error("Error updating near miss report:", error);
//       setIsLoading(false);
//     }
//   };

//   const handleImageChange = (e) => {
//     if (e.target.files.length > 0) {
//       setImageOfVerification(e.target.files[0]);
//     }
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const filteredReports = reports.filter((w) =>
//     tabValue === 0 ? w.isPending : !w.isPending
//   );

//   const handleAddActionPlan = () => {
//     if (actionPlans.length < 5) {
//       setActionPlans([
//         ...actionPlans,
//         {
//           TypeOfActionPlan: "",
//           ResponsiblePerson: "",
//           ApDescription: "",
//           TargetDate: new Date(),
//           status: "Open",
//         },
//       ]);
//     }
//   };

//   const handleRemoveActionPlan = (index) => {
//     const newActionPlans = actionPlans.filter((_, i) => i !== index);
//     setActionPlans(newActionPlans);
//   };

//   const handleActionPlanChange = (index, field, value) => {
//     const newActionPlans = [...actionPlans];
//     newActionPlans[index][field] = value;
//     setActionPlans(newActionPlans);
//   };

//   const handleSubmitInvestigation = async () => {
//     setIsLoading(true);
//     try {
//       const nearMissReportRef = firestore
//         .collection("company_data")
//         .doc(company_name)
//         .collection(company_name)
//         .doc("for_user")
//         .collection("near_miss_report")
//         .doc(selectedReport.id);
//       await nearMissReportRef.update({
//         InvestigationTeam: investigationTeam,
//         DateOfInvestigation: dateOfInvestigation,
//         TimeOfInvestigation: timeOfInvestigation,
//         rootCause: rootCause,
//         actionPlans: actionPlans,
//       });
//       setIsLoading(false);
//       handleModalClose();
//     } catch (error) {
//       console.error("Error updating investigation details:", error);
//       setIsLoading(false);
//     }
//   };

//   const canCloseNearMissReport = () => {
//     return (
//       actionPlans.length !== 0 &&
//       actionPlans.every((plan) => plan.status === "Close")
//     );
//   };

//   const getStatusColor = (severityLevel) => {
//     switch (severityLevel) {
//       case "minor":
//         return "#FFD700";
//       case "major":
//         return "#FFA500";
//       case "fatal":
//         return "#FF4500";
//       default:
//         return "#000000";
//     }
//   };

//   const severityLevel = "minor";
//   const borderColor = getStatusColor(severityLevel);

//   const formatTimestamp = (timestamp) => {
//     if (timestamp && timestamp.seconds) {
//       const date = new Date(timestamp.seconds * 1000);
//       return format(date, "dd-MM-yyyy HH:mm:ss");
//     }
//     return "";
//   };

//   const handleToggleExpand = () => {
//     setExpanded(!expanded);
//   };
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

//   const maxLength = 10;

//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <StyledPaper elevation={3}
//       >
//       <Container maxWidth="lg">
//       <Typography
//           variant="h5"
//           gutterBottom
//           sx={{ flexGrow:1} }
//         >
//           Near Miss Reports
//         </Typography>
//           {/* ... (keep your existing Tabs component) */}
//           <Tabs
//             value={tabValue}
//             onChange={handleTabChange}
//             variant="fullWidth"
//           >
//             <Tab label="Pending" icon={<Warning />} iconPosition="start"/>
//             <Tab label="Completed" icon={<CheckCircle />}
//             iconPosition="start"/>
//           </Tabs>
        
//         <Grid container spacing={2}>
//           {filteredReports.map((report) => (
//             <Grid item xs={12} sm={6} md={4} key={report.id}>
//               <Card elevation={2}>
//                 <CardContent sx={{ p: 2 }}>
//                   <Box
//                     display="flex"
//                     flexDirection={isMobile ? "column" : "row"}
//                     justifyContent="space-between"
//                     alignItems={isMobile ? "flex-start" : "center"}
//                     mb={2}
//                   >
//                     <Box mb={isMobile ? 2 : 0}>
//                       <Typography
//                         variant="h6"
//                         color="primary"
//                         gutterBottom
//                         fontWeight="bold"
//                         fontSize={isMobile ? "1rem" : "1.25rem"}
//                       >
//                         Case #{report.caseNumber}
//                       </Typography>
//                       <Typography
//                         variant="body2"
//                         color="text.secondary"
//                         gutterBottom
//                       >
//                         {report.areaName}, {report.departmentName}
//                       </Typography>
//                     </Box>
//                     <Chip
//                       label={report.isPending ? "Pending" : "Completed"}
//                       color={report.isPending ? "warning" : "success"}
//                       icon={<PendingActions />}
//                       sx={{ fontWeight: "bold", fontSize: "0.75rem" }}
//                     />
//                   </Box>
//                   <Typography
//                     variant="body2"
//                     gutterBottom
//                     sx={{ mb: 2, fontSize: "0.875rem" }}
//                   >
//                     {expanded ||
//                     report.descriptionOfNearMiss.length <= maxLength
//                       ? report.descriptionOfNearMiss
//                       : `${report.descriptionOfNearMiss.slice(
//                           0,
//                           maxLength
//                         )}...`}
//                   </Typography>
//                   {report.descriptionOfNearMiss.length > maxLength && (
//                     <Button
//                       onClick={handleToggleExpand}
//                       size="small"
//                       sx={{ mb: 2, color: "primary.main", fontSize: "0.75rem" }}
//                     >
//                       {expanded ? "Read Less" : "Read More"}
//                     </Button>
//                   )}
//                   <Divider sx={{ my: 2 }} />
//                   <Typography
//                     variant="body2"
//                     color="text.secondary"
//                     fontSize="0.75rem"
//                   >
//                     Reported by: {report.reportingPersonName}
//                   </Typography>
//                   <Typography
//                     variant="body2"
//                     color="text.secondary"
//                     sx={{ mb: 2 }}
//                     fontSize="0.75rem"
//                   >
//                     Date: {formatTimestamp(report.dateOfNearMiss)}
//                   </Typography>
//                   <Box
//                     mt={2}
//                     display="flex"
//                     flexDirection={isMobile ? "column" : "row"}
//                     justifyContent="space-between"
//                   >
//                     <Button
//                       variant="outlined"
//                       color="primary"
//                       startIcon={<Info />}
//                       onClick={() => handleDetailsModalOpen(report)}
//                       sx={{
//                         borderRadius: 20,
//                         mb: isMobile ? 1 : 0,
//                         width: isMobile ? "100%" : "auto",
//                         fontSize: "0.75rem",
//                       }}
//                     >
//                       Details
//                     </Button>
//                     {report.isPending && (
//                       <>
//                         <Button
//                           variant="outlined"
//                           color="warning"
//                           startIcon={<PriorityHigh />}
//                           onClick={() => handleInvestigationModalOpen(report)}
//                           sx={{
//                             borderRadius: 20,
//                             mb: isMobile ? 1 : 0,
//                             width: isMobile ? "100%" : "auto",
//                             fontSize: "0.75rem",
//                           }}
//                         >
//                           Investigate
//                         </Button>
//                         <Button
//                           variant="contained"
//                           color="error"
//                           startIcon={<Close />}
//                           onClick={() => handleFormModalOpen(report)}
//                           sx={{
//                             borderRadius: 20,
//                             width: isMobile ? "100%" : "auto",
//                             fontSize: "0.75rem",
//                           }}
//                         >
//                           Close
//                         </Button>
//                       </>
//                     )}
//                   </Box>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </Container>
        

//         {/* Details Modal */}
//         <Modal
//           open={isDetailsModalOpen}
//           onClose={handleModalClose}
//           aria-labelledby="near-miss-details-modal-title"
//         >
//           {/* <Box sx={{
//             position: 'absolute',
//             top: '50%',
//             left: '50%',
//             transform: 'translate(-50%, -50%)',
//             width: isMobile ? '90%' : isTablet ? '80%' : '60%',
//             bgcolor: 'background.paper',
//             boxShadow: 24,
//             p: 4,
//             maxHeight: '100vh',
//             overflowY: 'auto',
//           }}> */}
//           {/* ... (keep your existing modal content) */}
//           <ModalContent>
//             <IconButton
//               aria-label="close"
//               onClick={handleModalClose}
//               sx={{ position: "absolute", right: 8, top: 8 }}
//             >
//               <CloseIcon />
//             </IconButton>
//             {selectedReport && (
//               <Box>
//                 <Typography
//                   id="near-miss-details-modal-title"
//                   variant="h5"
//                   gutterBottom
//                   color="primary"
//                   fontWeight="bold"
//                 >
//                   Near Miss Details
//                 </Typography>
//                 <Grid container spacing={3}>
//                   <Grid item xs={12} sm={6}>
//                     <Typography variant="subtitle1" fontWeight="bold">
//                       Area Name:
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                       {selectedReport.areaName}
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <Typography variant="subtitle1" fontWeight="bold">
//                       Department Name:
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                       {selectedReport.departmentName}
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <Typography variant="subtitle1" fontWeight="bold">
//                       Employee ID:
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                       {selectedReport.employeeId}
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={6}>
//                     <Typography variant="subtitle1" fontWeight="bold">
//                       Description of Near Miss:
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                       {selectedReport.descriptionOfNearMiss}
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <Typography variant="subtitle1" fontWeight="bold">
//                       Reporting Person:
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                       {selectedReport.reportingPersonName}
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <Typography variant="subtitle1" fontWeight="bold">
//                       Reporting Person Designation:
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                       {selectedReport.reportingPersonDesignation}
//                     </Typography>
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <Typography variant="subtitle1" fontWeight="bold">
//                       Date of Near Miss:
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                       {formatDate(selectedReport.dateOfNearMiss)}
//                     </Typography>
//                   </Grid>
//                   {!selectedReport.isPending && (
//                     <>
//                       <Grid item xs={12} sm={6}>
//                         <Typography variant="subtitle1" fontWeight="bold">
//                           Date of Closing:
//                         </Typography>
//                         <Typography variant="body1" gutterBottom>
//                           {formatDate(selectedReport.dateOfClosing)}
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={12}>
//                         <Typography variant="subtitle1" fontWeight="bold">
//                           Verification Image:
//                         </Typography>
//                         {selectedReport.imageOfVerification ? (
//                           <Box sx={{ mt: 2, textAlign: "center" }}>
//                             <img
//                               src={selectedReport.imageOfVerification}
//                               alt="Verification"
//                               style={{
//                                 maxWidth: "100%",
//                                 maxHeight: "300px",
//                                 borderRadius: "8px",
//                               }}
//                             />
//                           </Box>
//                         ) : (
//                           <Typography variant="body1">
//                             No image provided
//                           </Typography>
//                         )}
//                       </Grid>
//                     </>
//                   )}
//                 </Grid>
//               </Box>
//             )}
//           </ModalContent>

//           {/* </Box> */}
//         </Modal>

//         {/* Form Modal */}
//         <Modal
//           open={isFormModalOpen}
//           onClose={handleModalClose}
//           aria-labelledby="near-miss-form-modal-title"
//         >
//           <Box
//             sx={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               width: isMobile ? "90%" : isTablet ? "80%" : "60%",
//               bgcolor: "background.paper",
//               boxShadow: 24,
//               p: 4,
//               maxHeight: "100vh",
//               overflowY: "auto",
//             }}
//           >
//             {/* ... (keep your existing form modal content) */}
//             <ModalContent>
//               <IconButton
//                 aria-label="close"
//                 onClick={handleModalClose}
//                 sx={{ position: "absolute", right: 8, top: 8 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//               <Typography
//                 id="near-miss-form-modal-title"
//                 variant="h6"
//                 component="h2"
//                 fontWeight="bold"
//                 sx={{ mb: 3 }}
//               >
//                 Close Near Miss Report
//               </Typography>
//               <Box
//                 component="form"
//                 noValidate
//                 autoComplete="off"
//                 sx={{ mt: 2 }}
//               >
//                 <DatePicker
//                   label="Date of Closing"
//                   value={date}
//                   onChange={handleDateChange}
//                   renderInput={(params) => (
//                     <TextField {...params} fullWidth sx={{ mb: 3 }} />
//                   )}
//                 />
//                 <input
//                   accept="image/*"
//                   id="contained-button-file"
//                   type="file"
//                   onChange={handleImageChange}
//                   style={{ display: "none" }}
//                 />
//                 <label htmlFor="contained-button-file">
//                   <Button
//                     variant="outlined"
//                     component="span"
//                     fullWidth
//                     sx={{ mb: 3, py: 1.5, borderRadius: 2 }}
//                     startIcon={<AddIcon />}
//                   >
//                     Upload Verification Image
//                   </Button>
//                 </label>
//                 {imageOfVerification && (
//                   <Typography variant="body2" sx={{ mb: 3 }}>
//                     Image selected: {imageOfVerification.name}
//                   </Typography>
//                 )}
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleCloseNearMissReport}
//                   disabled={isLoading || !canCloseNearMissReport()}
//                   fullWidth
//                   sx={{ py: 1.5, borderRadius: 2 }}
//                 >
//                   {isLoading ? (
//                     <CircularProgress size={24} />
//                   ) : (
//                     "Close Near Miss Report"
//                   )}
//                 </Button>
//               </Box>
//             </ModalContent>
//           </Box>
//         </Modal>

//         {/* Investigation Modal */}
//         <Modal
//           open={isInvestigationModalOpen}
//           onClose={handleModalClose}
//           aria-labelledby="investigation-modal-title"
//         >
//           <Box
//             sx={{
//               position: "absolute",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%, -50%)",
//               width: isMobile ? "90%" : isTablet ? "80%" : "60%",
//               bgcolor: "background.paper",
//               boxShadow: 24,
//               p: 4,
//               maxHeight: "90vh",
//               overflowY: "auto",
//             }}
//           >
//             {/* ... (keep your existing investigation modal content) */}
//             <>
//               <IconButton
//                 aria-label="close"
//                 onClick={handleModalClose}
//                 sx={{ position: "absolute", right: 8, top: 8 }}
//               >
//                 <CloseIcon />
//               </IconButton>
//               <Typography
//                 id="investigation-modal-title"
//                 variant="h6"
//                 component="h2"
//                 gutterBottom
//                 fontWeight="bold"
//                 sx={{ mb: 3 }}
//               >
//                 Investigation Details
//               </Typography>
//               <TextField
//                 label="Investigation Team"
//                 value={investigationTeam}
//                 onChange={(e) => setInvestigationTeam(e.target.value)}
//                 fullWidth
//                 margin="normal"
//                 sx={{ mb: 3 }}
//               />
//               <DatePicker
//                 label="Date of Investigation"
//                 value={dateOfInvestigation}
//                 onChange={(newValue) => setDateOfInvestigation(newValue)}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     fullWidth
//                     margin="normal"
//                     sx={{ mb: 3 }}
//                   />
//                 )}
//               />
//               <TimePicker
//                 label="Time of Investigation"
//                 value={timeOfInvestigation}
//                 onChange={(newValue) => setTimeOfInvestigation(newValue)}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     fullWidth
//                     margin="normal"
//                     sx={{ mb: 3 }}
//                   />
//                 )}
//               />
//               <TextField
//                 label="Root Cause"
//                 value={rootCause}
//                 onChange={(e) => setRootCause(e.target.value)}
//                 fullWidth
//                 multiline
//                 rows={4}
//                 margin="normal"
//                 sx={{ mb: 3 }}
//               />
//               {actionPlans.map((plan, index) => (
//                 <ActionPlanForm key={index}>
//                   <Typography
//                     variant="subtitle1"
//                     gutterBottom
//                     fontWeight="bold"
//                   >
//                     Action Plan {index + 1}
//                   </Typography>
//                   <Grid container spacing={3}>
//                     <Grid item xs={12} sm={6}>
//                       <FormControl fullWidth margin="normal">
//                         <InputLabel>Type of Action Plan</InputLabel>
//                         <Select
//                           value={plan.TypeOfActionPlan}
//                           onChange={(e) =>
//                             handleActionPlanChange(
//                               index,
//                               "TypeOfActionPlan",
//                               e.target.value
//                             )
//                           }
//                         >
//                           <MenuItem value="Elimination">Elimination</MenuItem>
//                           <MenuItem value="Substitution">Substitution</MenuItem>
//                           <MenuItem value="Engineering Controls">
//                             Engineering Controls
//                           </MenuItem>
//                           <MenuItem value="Administrative Controls">
//                             Administrative Controls
//                           </MenuItem>
//                           <MenuItem value="PPE">PPE</MenuItem>
//                         </Select>
//                       </FormControl>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <TextField
//                         label="Responsible Person"
//                         value={plan.ResponsiblePerson}
//                         onChange={(e) =>
//                           handleActionPlanChange(
//                             index,
//                             "ResponsiblePerson",
//                             e.target.value
//                           )
//                         }
//                         fullWidth
//                         margin="normal"
//                       />
//                     </Grid>
//                     <Grid item xs={12}>
//                       <TextField
//                         label="Description"
//                         value={plan.ApDescription}
//                         onChange={(e) =>
//                           handleActionPlanChange(
//                             index,
//                             "ApDescription",
//                             e.target.value
//                           )
//                         }
//                         fullWidth
//                         multiline
//                         rows={3}
//                         margin="normal"
//                       />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <DatePicker
//                         label="Target Date"
//                         value={plan.TargetDate}
//                         onChange={(newValue) =>
//                           handleActionPlanChange(index, "TargetDate", newValue)
//                         }
//                         renderInput={(params) => (
//                           <TextField {...params} fullWidth margin="normal" />
//                         )}
//                       />
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <FormControl fullWidth margin="normal">
//                         <InputLabel>Status</InputLabel>
//                         <Select
//                           value={plan.Status}
//                           onChange={(e) =>
//                             handleActionPlanChange(
//                               index,
//                               "Status",
//                               e.target.value
//                             )
//                           }
//                         >
//                           <MenuItem value="Open">Open</MenuItem>
//                           <MenuItem value="In Progress">In Progress</MenuItem>
//                           <MenuItem value="Completed">Completed</MenuItem>
//                         </Select>
//                       </FormControl>
//                     </Grid>
//                   </Grid>
//                   <Box
//                     sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
//                   >
//                     <Button
//                       variant="outlined"
//                       color="error"
//                       startIcon={<DeleteIcon />}
//                       onClick={() => handleRemoveActionPlan(index)}
//                     >
//                       Remove
//                     </Button>
//                   </Box>
//                 </ActionPlanForm>
//               ))}
//               <Button
//                 variant="outlined"
//                 color="primary"
//                 startIcon={<AddIcon />}
//                 onClick={handleAddActionPlan}
//                 sx={{ mt: 2 }}
//               >
//                 Add Action Plan
//               </Button>
//               <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleSubmitInvestigation}
//                   disabled={isLoading}
//                 >
//                   {isLoading ? (
//                     <CircularProgress size={24} />
//                   ) : (
//                     "Submit Investigation"
//                   )}
//                 </Button>
//               </Box>
//             </>
//           </Box>
//         </Modal>
//       </StyledPaper>
//     </LocalizationProvider>
//   );
// };

// export default NearMissReportList;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  Button,
  Divider,
  Modal,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Tabs,
  Tab,
  Container,
  Grid,
  useTheme,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";
import { firestore } from "../../firebase/firebaseConfig";
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  actionPlanForm: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const StyledCard = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const NearMissReportList = () => {
  // ... (keep all existing state variables and functions)
  const classes = useStyles();
    // ... (all state variables and functions remain the same)

  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isInvestigationModalOpen, setIsInvestigationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [tabValue, setTabValue] = useState(0);
  const [imageOfVerification, setImageOfVerification] = useState(null);
  const location = useLocation();
  const { company_name } = location.state || {};

  const [investigationTeam, setInvestigationTeam] = useState("");
  const [dateOfInvestigation, setDateOfInvestigation] = useState(new Date());
  const [timeOfInvestigation, setTimeOfInvestigation] = useState(new Date());
  const [rootCause, setRootCause] = useState("");
  const [actionPlans, setActionPlans] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetchReports = async () => {
      const reportsRef = firestore
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("for_user")
        .collection("near_miss_report");
      const snapshot = await reportsRef.get();
      const reportsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedReportsData = reportsData.sort((a, b) => {
        const caseNumberA = parseInt(a.caseNumber, 10);
        const caseNumberB = parseInt(b.caseNumber, 10);
        return caseNumberB - caseNumberA;
      });

      setReports(sortedReportsData);
    };
    fetchReports();
  }, [company_name]);

  const formatDate = (timestamp) => {
    if (typeof timestamp === 'string' && timestamp.includes('-')) {
      return timestamp;
    }
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('MMMM D, YYYY');
    }
    return 'Invalid Date';
  };

  const formatTime = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format('h:mm A');
    }
    return 'Invalid Time';
  };

  const handleDetailsModalOpen = (report) => {
    setSelectedReport(report);
    setIsDetailsModalOpen(true);
  };

  const handleFormModalOpen = (report) => {
    setSelectedReport(report);
    setIsFormModalOpen(true);
  };

  const handleInvestigationModalOpen = (report) => {
    setSelectedReport(report);
    setIsInvestigationModalOpen(true);
    if (report.DateOfInvestigation) {
      setInvestigationTeam(report.InvestigationTeam || "");
      setDateOfInvestigation(report.DateOfInvestigation.toDate());
      setTimeOfInvestigation(report.TimeOfInvestigation.toDate());
      setRootCause(report.rootCause || "");
      setActionPlans(report.actionPlans || []);
      setIsEditMode(true);
    } else {
      setInvestigationTeam("");
      setDateOfInvestigation(new Date());
      setTimeOfInvestigation(new Date());
      setRootCause("");
      setActionPlans([]);
      setIsEditMode(false);
    }
  };

  const handleModalClose = () => {
    setIsDetailsModalOpen(false);
    setIsFormModalOpen(false);
    setIsInvestigationModalOpen(false);
    setSelectedReport(null);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const uploadImage = async (file) => {
    if (!file) return null;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(
      `near_miss_report_images/${Date.now()}_${file.name}`
    );

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      throw error;
    }
  };

  const handleCloseNearMissReport = async () => {
    setIsLoading(true);
    let imageUrl = null;
    if (imageOfVerification) {
      imageUrl = await uploadImage(imageOfVerification);
    }
    try {
      const nearMissReportRef = firestore
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("for_user")
        .collection("near_miss_report")
        .doc(selectedReport.id);
      await nearMissReportRef.update({
        isPending: false,
        dateOfClosing: date,
        imageOfVerification: imageUrl,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error("Error updating near miss report:", error);
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImageOfVerification(e.target.files[0]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredReports = reports.filter((w) =>
    tabValue === 0 ? w.isPending : !w.isPending
  );

  const handleAddActionPlan = () => {
    if (actionPlans.length < 5) {
      setActionPlans([
        ...actionPlans,
        {
          TypeOfActionPlan: "",
          ResponsiblePerson: "",
          ApDescription: "",
          TargetDate: new Date(),
          status: "Open",
        },
      ]);
    }
  };

  const handleRemoveActionPlan = (index) => {
    const newActionPlans = actionPlans.filter((_, i) => i !== index);
    setActionPlans(newActionPlans);
  };

  const handleActionPlanChange = (index, field, value) => {
    const newActionPlans = [...actionPlans];
    newActionPlans[index][field] = value;
    setActionPlans(newActionPlans);
  };

  const handleSubmitInvestigation = async () => {
    setIsLoading(true);
    try {
      const nearMissReportRef = firestore
        .collection("company_data")
        .doc(company_name)
        .collection(company_name)
        .doc("for_user")
        .collection("near_miss_report")
        .doc(selectedReport.id);
      await nearMissReportRef.update({
        InvestigationTeam: investigationTeam,
        DateOfInvestigation: dateOfInvestigation,
        TimeOfInvestigation: timeOfInvestigation,
        rootCause: rootCause,
        actionPlans: actionPlans,
      });
      setIsLoading(false);
      handleModalClose();
    } catch (error) {
      console.error("Error updating investigation details:", error);
      setIsLoading(false);
    }
  };

  const canCloseNearMissReport = () => {
    return (
      actionPlans.length !== 0 &&
      actionPlans.every((plan) => plan.status === "Close")
    );
  };

  const getStatusColor = (severityLevel) => {
    switch (severityLevel) {
      case "minor":
        return "#FFD700";
      case "major":
        return "#FFA500";
      case "fatal":
        return "#FF4500";
      default:
        return "#000000";
    }
  };

  const severityLevel = "minor";
  const borderColor = getStatusColor(severityLevel);

  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return format(date, "dd-MM-yyyy HH:mm:ss");
    }
    return "";
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const maxLength = 10;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledPaper elevation={3}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            Near Miss Reports
          </Typography>
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label="Pending" />
            <Tab label="Completed" />
          </Tabs>
          <Box mt={3}>
            <Grid container spacing={2}>
              {filteredReports.map((report) => (
                <Grid item xs={12} sm={6} md={6} key={report.id}>
                  <StyledCard elevation={1}>
                    <CardContent>
                      <Typography><strong>Case Number:</strong> {report.caseNumber}</Typography>
                      <Typography><strong>Reported on:</strong> {formatDate(report.dateOfNearMiss)}</Typography>
                      <Typography><strong>Area Name:</strong> {report.areaName}</Typography>
                      <Typography><strong>Description:</strong> 
                      {report.descriptionOfNearMiss.length > 40 
                      ?`${report.descriptionOfNearMiss.substring(0, 100)}...`
                      : report.descriptionOfNearMiss}
                      </Typography>
                      <Typography color={report.isPending ? 'error' : 'green'}><strong>Status:</strong>{report.isPending ? 'Pending' : 'Completed'}</Typography>
                      <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={() => handleDetailsModalOpen(report)} style={{ marginRight: '10px' }}>
                          View Details
                        </Button>
                        {report.isPending && (
                          <Button variant="contained" color="secondary" onClick={() => handleInvestigationModalOpen(report)} style={{ marginRight: '10px' }}>
                            Investigate
                          </Button>
                        )}
                        {report.isPending && (
                          <Button variant="contained" color="secondary" onClick={() => handleFormModalOpen(report)}>
                            Close Form
                          </Button>
                        )}
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Details Modal */}
          <Modal
            open={isDetailsModalOpen}
            onClose={handleModalClose}
            aria-labelledby="near-miss-details-modal-title"
          >
            <Box className={classes.modalContent}>
              <IconButton
                aria-label="close"
                onClick={handleModalClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              {selectedReport && (
                <Box>
                  <Typography id="near-miss-details-modal-title" variant="h6" gutterBottom>Near Miss Details</Typography>
                  <Typography>Description: {selectedReport.descriptionOfNearMiss}</Typography>
                  <Typography>Date: {formatDate(selectedReport.dateOfNearMiss)}</Typography>
                  <Typography>Case Number: {selectedReport.caseNumber}</Typography>
                  <Typography>Area Name: {selectedReport.areaName}</Typography>
                  <Typography>Department Name: {selectedReport.departmentName}</Typography>
                  <Typography>Employee ID: {selectedReport.employeeId}</Typography>
                  <Typography>Reporting Person Name: {selectedReport.reportingPersonName}</Typography>
                  <Typography>Reporting Person Designation: {selectedReport.reportingPersonDesignation}</Typography>
                  <Box mt={2}>
                    <Button variant="outlined" onClick={handleModalClose}>
                      Back to list
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>

          {/* Investigation Modal */}
          <Modal
            open={isInvestigationModalOpen}
            onClose={handleModalClose}
            aria-labelledby="near-miss-investigation-modal-title"
          >
            <Box className={classes.modalContent}>
              <IconButton
                aria-label="close"
                onClick={handleModalClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              {selectedReport && (
                <Box>
                  <Typography id="near-miss-investigation-modal-title" variant="h6" gutterBottom>Near Miss Investigation</Typography>
                  <TextField
                    label="Investigation Team"
                    value={investigationTeam}
                    onChange={(e) => setInvestigationTeam(e.target.value)}
                    fullWidth
                    margin="normal"
                    disabled={isEditMode && !isLoading}
                  />
                  <DatePicker
                    label="Date of Investigation"
                    value={dateOfInvestigation}
                    onChange={setDateOfInvestigation}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    disabled={isEditMode && !isLoading}
                  />
                  <TimePicker
                    label="Time of Investigation"
                    value={timeOfInvestigation}
                    onChange={setTimeOfInvestigation}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    disabled={isEditMode && !isLoading}
                  />
                  <TextField
                    label="Root Cause"
                    value={rootCause}
                    onChange={(e) => setRootCause(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    disabled={isEditMode && !isLoading}
                  />
                  <Typography variant="h6" gutterBottom>Action Plans</Typography>
                  {actionPlans.map((plan, index) => (
                    <Box key={index} className={classes.actionPlanForm}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Type of Action Plan</InputLabel>
                        <Select
                          value={plan.TypeOfActionPlan}
                          onChange={(e) => handleActionPlanChange(index, 'TypeOfActionPlan', e.target.value)}
                          disabled={isEditMode && !isLoading}
                        >
                          <MenuItem value="corrective">Corrective AP</MenuItem>
                          <MenuItem value="preventive">Preventive AP</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        label="Action plan description"
                        value={plan.ApDescription}
                        onChange={(e) => handleActionPlanChange(index, 'ApDescription', e.target.value)}
                        fullWidth
                        multiline
                        rows={2}
                        margin="normal"
                        disabled={isEditMode && !isLoading}
                      />
                      <TextField
                        label="Responsible Person"
                        value={plan.ResponsiblePerson}
                        onChange={(e) => handleActionPlanChange(index, 'ResponsiblePerson', e.target.value)}
                        fullWidth
                        margin="normal"
                        disabled={isEditMode && !isLoading}
                      />
                      <DatePicker
                        label="Target Date"
                        value={plan.TargetDate}
                        onChange={(date) => handleActionPlanChange(index, 'TargetDate', date)}
                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                        disabled={isEditMode && !isLoading}
                      />
                      <FormControl fullWidth margin="normal">
                        <InputLabel>CAPA Status</InputLabel>
                        <Select
                          value={plan.status}
                          onChange={(e) => handleActionPlanChange(index, 'status', e.target.value)}
                          disabled={isEditMode && !isLoading}
                        >
                          <MenuItem value="Open">Open</MenuItem>
                          <MenuItem value="Close">Close</MenuItem>
                        </Select>
                      </FormControl>
                      {!isEditMode && (
                        <Button
                          startIcon={<DeleteIcon />}
                          onClick={() => handleRemoveActionPlan(index)}
                          disabled={isLoading}
                        >
                          Remove
                        </Button>
                      )}
                    </Box>
                  ))}
                  {!isEditMode && actionPlans.length < 5 && (
                    <Button
                      startIcon={<AddIcon />}
                      onClick={handleAddActionPlan}
                      disabled={isLoading}
                    >
                      Add Action Plan
                    </Button>
                  )}
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    {isEditMode ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIsEditMode(false)}
                        disabled={isLoading}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitInvestigation}
                        disabled={isLoading}
                      >
                        Submit Investigation
                      </Button>
                    )}
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                </Box>
              )}
            </Box>
          </Modal>

          {/* Close Form Modal */}
          <Modal
            open={isFormModalOpen}
            onClose={handleModalClose}
            aria-labelledby="near-miss-form-modal-title"
          >
            <Box className={classes.modalContent}>
              <IconButton
                aria-label="close"
                onClick={handleModalClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              {selectedReport && (
                <Box>
                  <Typography id="near-miss-form-modal-title" variant="h6" gutterBottom>Close Near Miss Report</Typography>
                  <Typography>Case Number: {selectedReport.caseNumber}</Typography>
                  
                  {/* Display Action Plan Progress */}
                  <Typography variant="h6" gutterBottom>Action Plan Progress</Typography>
                  {selectedReport.actionPlans && selectedReport.actionPlans.map((plan, index) => (
                    <React.Fragment key={index}>
                      <Typography>
                        Action Plan {index + 1}: {plan.ApDescription}
                      </Typography>
                      <Typography>
                        Status: {plan.status}
                      </Typography>
                    </React.Fragment>
                  ))}
                  <Typography>Date of Closing*</Typography>
                  <DatePicker
                    value={date}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    disabled={true}
                  />
                  <Grid item xs={12}>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                      disabled={!canCloseNearMissReport()}
                    />
                    <label htmlFor="contained-button-file">
                      <Button variant="contained" color="primary" component="span">
                        Upload Image for Verification
                      </Button>
                    </label>
                    {imageOfVerification && <Typography>{imageOfVerification.name}</Typography>}
                  </Grid>
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCloseNearMissReport}
                      disabled={isLoading || !canCloseNearMissReport()}
                    >
                      {isLoading ? 'Closing...' : 'Close the form'}
                    </Button>
                    {isLoading && <CircularProgress size={24} />}
                  </Box>
                  {!canCloseNearMissReport() && (
                    <Typography color="error">
                      All action plans must be closed before closing the near miss report.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Modal>
        </Container>
      </StyledPaper>
    </LocalizationProvider>
  );
};

export default NearMissReportList;