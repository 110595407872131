import React from 'react';
import { Button, IconButton } from '@mui/material';
import { AddAPhoto, Clear } from '@mui/icons-material';

const ImageUploadButton = ({ sectionId, id, handleImageUpload, images }) => {
  const handleFileChange = (e) => {
    handleImageUpload(sectionId, id, e.target.files[0]);
  };

  return (
    <div>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={`upload-button-${sectionId}-${id}`}
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor={`upload-button-${sectionId}-${id}`}>
        <Button
          variant="contained"
          component="span"
          startIcon={<AddAPhoto />}
        >
          Upload Image
        </Button>
      </label>
      {images[sectionId]?.[id] && (
        <IconButton
          onClick={() => handleImageUpload(sectionId, id, null)}
          aria-label="delete"
        >
          <Clear />
        </IconButton>
      )}
    </div>
  );
};

export default ImageUploadButton;