import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, useMediaQuery, Menu, MenuItem, ThemeProvider } from "@mui/material";
import { ArrowBack } from "@material-ui/icons";
import   theme  from "../../theme";

const MyAppBar = ({ onScrollTo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname === "/Login/User";
  const isLoginPage1 = location.pathname === "/Login/Admin";
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleScrollTo = (section) => {
    onScrollTo(section);
    navigate("/");
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLoginClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoginClose = () => {
    setAnchorEl(null);
  };

  const handleUserLogin = () => {
    navigate("/Login/User");
    handleLoginClose();
  };

  const handleAdminLogin = () => {
    navigate("/Login/Admin");
    handleLoginClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky">
      <Toolbar sx={{display:'flex',justifyContent:'space-evenly',backgroundColor:'#fff',color:'#000000'}}>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}  textAlign={'center'} mr={40} >
          <IconButton onClick={() => handleScrollTo("home")} edge="start" aria-label="menu">
            <img src="/Images/Kavach.png" style={{width:40}} alt="Kavach Logo" />
          </IconButton>
          <Typography
          onClick={() => handleScrollTo("home")}
          variant={isMobile ? "h6" : "h5"}
          sx={{ml:2}}
        >
          Kavach
        </Typography>
        </Box>
        <Box ml={40}>
        {!isMobile ? (
          <>
            {isLoginPage || isLoginPage1 ? (
              <>
              <Button color="inherit" component={Link} to="/">
                <Typography variant="h6">Home</Typography>
              </Button>
              <Button color="inherit" component={Link} to="https://kavachpro.com/pricing-plan/">
                <Typography variant="h6">Pricing</Typography>
              </Button>
              <Button color="inherit" component={Link} to=" https://kavachpro.com/about-us/">
                <Typography variant="h6">About Us</Typography>
              </Button>
              <Button color="inherit" component={Link} to="https://kavachpro.com/contact-us/ ">
                <Typography variant="h6">Contact Us</Typography>
              </Button>
              </>
            ) : (
              <>
                <Button color="inherit" onClick={() => handleScrollTo("home")}>
                  <Typography variant="h6">Home</Typography>
                </Button>
                <Button color="inherit" onClick={() => handleScrollTo("pricing")}>
                  <Typography variant="h6">Pricing</Typography>
                </Button>
                <Button color="inherit" onClick={() => handleScrollTo("about")}>
                  <Typography variant="h6">About Us</Typography>
                </Button>
                <Button color="inherit" onClick={() => handleScrollTo("contact")}>
                  <Typography variant="h6">Contact Us</Typography>
                </Button>
              </>
            )}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#28CC9E",
                color: "white",
                ml:2,
                "&:hover": {
                  backgroundColor: "#004b23", // Hover background color
                },
              }}
              onClick={handleLoginClick}
            >
              Login
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleLoginClose}
            >
              <MenuItem onClick={handleUserLogin}>User Login</MenuItem>
              <MenuItem onClick={handleAdminLogin}>Admin Login</MenuItem>
            </Menu>
          </>
        ) : (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        )} 
        </Box>
       
      </Toolbar>

      {/* Drawer for mobile */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <div style={{ width: 150 }}>
          <Button onClick={handleDrawerClose}>
            <ArrowBack />
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoginPage ? (
              <Button color="inherit" component={Link} to="/" sx={{ transition: "transform 0.3s ease-in-out", "&:hover": { transform: "scale(1.05)" } }}>
                Home
              </Button>
            ) : (
              <>
                <Button color="inherit" onClick={() => handleScrollTo("home")}>
                  Home
                </Button>
                <Button color="inherit" onClick={() => handleScrollTo("pricing")}>
                  Pricing
                </Button>
                <Button color="inherit" onClick={() => handleScrollTo("about")}>
                  About Us
                </Button>
                <Button color="inherit" onClick={() => handleScrollTo("contact")}>
                  Contact Us
                </Button>
              </>
            )}
            <Button
              variant="inherit"
              color="inherit"
              sx={{
                backgroundColor: "#002244",
                color: "white",
                width: 20,
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
              onClick={handleLoginClick}
            >
              Login
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleLoginClose}
            >
              <MenuItem onClick={handleUserLogin}>User Login</MenuItem>
              <MenuItem onClick={handleAdminLogin}>Admin Login</MenuItem>
            </Menu>
          </div>
        </div>
      </Drawer>
    </AppBar>
    </ThemeProvider>
    
  );
};

export default MyAppBar;

// import React, { useState } from "react";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import Drawer from "@mui/material/Drawer";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Box, useMediaQuery, Menu, MenuItem } from "@mui/material";
// import { ArrowBack } from "@material-ui/icons";

// const MyAppBar = ({ onScrollTo }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const isLoginPage = location.pathname.startsWith("/Login"); // Check if the path starts with "/Login"
//   const isHomePage = location.pathname === "/"; // Check if the user is on the home page
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

//   const handleScrollTo = (section) => {
//     onScrollTo(section);
//     navigate("/");
//   };

//   const handleDrawerToggle = () => {
//     setDrawerOpen(!drawerOpen);
//   };

//   const handleDrawerClose = () => {
//     setDrawerOpen(false);
//   };

//   const handleLoginClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleLoginClose = () => {
//     setAnchorEl(null);
//   };

//   const handleUserLogin = () => {
//     navigate("/Login/User");
//     handleLoginClose();
//   };

//   const handleAdminLogin = () => {
//     navigate("/Login/Admin");
//     handleLoginClose();
//   };

//   return (
//     <AppBar position="sticky" sx={{ backgroundColor: "#fffefe" }}>
//       <Toolbar sx={{ color: "black" }}>
//         <Box>
//           <IconButton onClick={() => handleScrollTo("home")} edge="start" color="inherit" aria-label="menu">
//             <img src="/Images/Kavach.png" style={{ width: "100%", maxWidth: 30 }} alt="Kavach Logo" />
//           </IconButton>
//         </Box>

//         <Typography
//           onClick={() => handleScrollTo("home")}
//           variant={isMobile ? "h6" : "h5"}
//           sx={{ flexGrow: 1, fontWeight: "bold" }}
//         >
//           Kavach
//         </Typography>

//         {!isMobile ? (
//           <>
//             {/* Home Button */}
//             {isHomePage ? (
//               <Button color="inherit" onClick={() => handleScrollTo("home")} disabled={isLoginPage}>
//                 Home
//               </Button>
//             ) : (
//               <Button color="inherit" component={Link} to="/" disabled={isLoginPage}>
//                 Home
//               </Button>
//             )}

//             <Button color="inherit" onClick={() => handleScrollTo("pricing")} disabled={isLoginPage}>
//               Pricing
//             </Button>
//             <Button color="inherit" onClick={() => handleScrollTo("about")} disabled={isLoginPage}>
//               About Us
//             </Button>
//             <Button color="inherit" onClick={() => handleScrollTo("contact")} disabled={isLoginPage}>
//               Contact Us
//             </Button>

//             {!isLoginPage && (
//               <Button
//                 variant="contained"
//                 sx={{
//                   backgroundColor: "#002244",
//                   color: "white",
//                   padding: "12px 20px",
//                 }}
//                 onClick={handleLoginClick}
//               >
//                 Login
//               </Button>
//             )}
//             <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleLoginClose}>
//               <MenuItem onClick={handleUserLogin}>User Login</MenuItem>
//               <MenuItem onClick={handleAdminLogin}>Admin Login</MenuItem>
//             </Menu>
//           </>
//         ) : (
//           <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
//             <MenuIcon />
//           </IconButton>
//         )}
//       </Toolbar>

//       {/* Drawer for mobile */}
//       <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
//         <div style={{ width: 150 }}>
//           <Button onClick={handleDrawerClose}>
//             <ArrowBack />
//           </Button>
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             {/* Home Button in Drawer */}
//             {isHomePage ? (
//               <Button color="inherit" onClick={() => handleScrollTo("home")} disabled={isLoginPage}>
//                 Home
//               </Button>
//             ) : (
//               <Button color="inherit" component={Link} to="/" disabled={isLoginPage}>
//                 Home
//               </Button>
//             )}

//             <Button color="inherit" onClick={() => handleScrollTo("pricing")} disabled={isLoginPage}>
//               Pricing
//             </Button>
//             <Button color="inherit" onClick={() => handleScrollTo("about")} disabled={isLoginPage}>
//               About Us
//             </Button>
//             <Button color="inherit" onClick={() => handleScrollTo("contact")} disabled={isLoginPage}>
//               Contact Us
//             </Button>

//             {!isLoginPage && (
//               <Button
//                 variant="inherit"
//                 color="inherit"
//                 sx={{
//                   backgroundColor: "#002244",
//                   color: "white",
//                   width: 20,
//                   transition: "transform 0.3s ease-in-out",
//                   "&:hover": {
//                     transform: "scale(1.05)",
//                   },
//                 }}
//                 onClick={handleLoginClick}
//               >
//                 Login
//               </Button>
//             )}
//             <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleLoginClose}>
//               <MenuItem onClick={handleUserLogin}>User Login</MenuItem>
//               <MenuItem onClick={handleAdminLogin}>Admin Login</MenuItem>
//             </Menu>
//           </div>
//         </div>
//       </Drawer>
//     </AppBar>
//   );
// };

// export default MyAppBar;
