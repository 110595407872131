import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DetailedDataTable from "./work_permit/DetailedDataTable";

import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomCard from "../../componants/CustomCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const FullScreenContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

//console.log('hii');
const WorkPermitChart = ({ company_name }) => {
  //  const WorkPermitChart = ({ companyName }) => {
  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [workPermitTypes, setWorkPermitTypes] = useState({});
  //const company_name = companyName;
  // console.log('company_name', company_name)
  const [chartData, setChartData] = useState({
    issuedVsClosed: null,
    inspectedVsCompiled: null,
    departmentIssuedVsClosed: null,
    departmentInspectedVsCompiled: null,
  });
  const [totals, setTotals] = useState({
    totalIssued: 0,
    totalClosed: 0,
    totalInspected: 0,
    totalComplied: 0,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("");

  const [monthlyData, setMonthlyData] = useState(null);

  const [fullScreenChart, setFullScreenChart] = useState(null);
  const [chartSizes, setChartSizes] = useState([
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
  ]);

  const [open, setOpen] = useState(false);
  const [currentChart, setCurrentChart] = useState(null);

  const chartRefs = useRef([]);

  const formatDate = (date) => {
    if (date instanceof firebase.firestore.Timestamp) {
      return date.toDate().toLocaleDateString();
    } else if (typeof date === "string") {
      return new Date(date).toLocaleDateString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return "Invalid Date";
  };
  const fetchData = async () => {
    setLoading(true);

    const db = firebase.firestore();
    const workPermitCollection = db
      .collection("company_data")
      .doc(company_name)
      .collection(company_name)
      .doc("for_user")
      .collection("work_permit");

    const querySnapshot = await workPermitCollection.get();

    const monthlyDataObj = {};

    const monthlyData = {};
    const departmentData = {};
    let totalIssued = 0;
    let totalClosed = 0;
    let totalInspected = 0;
    let totalComplied = 0;
    const typeData = {};
    // console.log('query_snapshot',querySnapshot);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const {
        departmentName,
        noOfWorkPermitIssued,
        noOfWorkPermitClosed,
        noOfWorkPermitComplied,
        noOfWorkPermitInspected,
        workPermitType,
      } = data;
      const dateObj = data.date.toDate
        ? data.date.toDate()
        : new Date(data.date);
      const month = dateObj.getMonth();
      const year = dateObj.getFullYear();
      const monthLabel = `${new Date(year, month).toLocaleString("default", {
        month: "short",
      })} ${year}`;

      const date1 = dayjs(formatDate(data.date));
      // console.log('date',monthLabel, workPermitType);
      if (dateObj >= startDate && dateObj <= endDate) {
        // console.log('month',monthLabel);

        const monthYear = date1.format("MMM YY");
        monthlyDataObj[monthYear] = (monthlyDataObj[monthYear] || 0) + 1;

        // Monthly data
        if (!monthlyData[monthLabel]) {
          monthlyData[monthLabel] = {
            issued: 0,
            closed: 0,
            inspected: 0,
            complied: 0,
          };
        }
        monthlyData[monthLabel].issued += noOfWorkPermitIssued;
        monthlyData[monthLabel].closed += noOfWorkPermitClosed;
        monthlyData[monthLabel].inspected += noOfWorkPermitInspected;
        monthlyData[monthLabel].complied += noOfWorkPermitComplied;

        // Department data
        if (!departmentData[departmentName]) {
          departmentData[departmentName] = {
            issued: 0,
            closed: 0,
            inspected: 0,
            complied: 0,
          };
        }
        departmentData[departmentName].issued += noOfWorkPermitIssued;
        departmentData[departmentName].closed += noOfWorkPermitClosed;
        departmentData[departmentName].inspected += noOfWorkPermitInspected;
        departmentData[departmentName].complied += noOfWorkPermitComplied;

        //type of work permit data
        // if (typeData[workPermitType]) {
        //    typeData[workPermitType]++;
        //  // typeData[workPermitType].issued += noOfWorkPermitIssued;
        // } else {
        //   typeData[workPermitType] = 1;
        // }

        if (!typeData[workPermitType]) {
          typeData[workPermitType] = {
            issued: 0,
            closed: 0,
            inspected: 0,
            complied: 0,
          };
        }
        typeData[workPermitType].issued += noOfWorkPermitIssued;

        // Totals
        totalIssued += noOfWorkPermitIssued;
        totalClosed += noOfWorkPermitClosed;
        totalInspected += noOfWorkPermitInspected;
        totalComplied += noOfWorkPermitComplied;
      }
    });
    setWorkPermitTypes(typeData);

    const sortedMonthLabels = Object.keys(monthlyData).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    setMonthlyData({
      labels: Object.keys(monthlyDataObj).sort((a, b) =>
        dayjs(a, "MMM YY").diff(dayjs(b, "MMM YY"))
      ),
      datasets: [
        {
          label: "Work permit Reported",
          data: Object.values(monthlyDataObj),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    });
    setChartData({
      issuedVsClosed: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Issued",
            data: sortedMonthLabels.map((label) => monthlyData[label].issued),
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
          {
            label: "Closed",
            data: sortedMonthLabels.map((label) => monthlyData[label].closed),
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      },
      inspectedVsCompiled: {
        labels: sortedMonthLabels,
        datasets: [
          {
            label: "Inspected",
            data: sortedMonthLabels.map(
              (label) => monthlyData[label].inspected
            ),
            backgroundColor: "rgba(54, 162, 235, 0.6)",
          },
          {
            label: "Compiled",
            data: sortedMonthLabels.map((label) => monthlyData[label].complied),
            backgroundColor: "rgba(255, 206, 86, 0.6)",
          },
        ],
      },
      departmentIssuedVsClosed: {
        labels: Object.keys(departmentData),
        datasets: [
          {
            label: "Issued",
            data: Object.values(departmentData).map((d) => d.issued),
            backgroundColor: "rgba(75, 192, 192, 0.6)",
          },
          {
            label: "Closed",
            data: Object.values(departmentData).map((d) => d.closed),
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      },
      departmentInspectedVsCompiled: {
        labels: Object.keys(departmentData),
        datasets: [
          {
            label: "Inspected",
            data: Object.values(departmentData).map((d) => d.inspected),
            backgroundColor: "rgba(54, 162, 235, 0.6)",
          },
          {
            label: "Compiled",
            data: Object.values(departmentData).map((d) => d.complied),
            backgroundColor: "rgba(255, 206, 86, 0.6)",
          },
        ],
      },
    });

    setTotals({ totalIssued, totalClosed, totalInspected, totalComplied });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [company_name, startDate, endDate]);

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;
    let yOffset = margin; // Initial Y offset for the first chart

    const addChartToPDF = (chartRef, callback) => {
      html2canvas(chartRef, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Check if the image fits in the current page, if not add a new page
        if (yOffset + imgHeight + margin > pageHeight) {
          pdf.addPage();
          yOffset = margin;
        }

        pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + margin; // Add some space between charts

        callback();
      });
    };

    const processCharts = (index) => {
      if (index < chartRefs.current.length) {
        addChartToPDF(chartRefs.current[index], () => {
          processCharts(index + 1);
        });
      } else {
        pdf.save("work_permit_charts.pdf");
      }
    };
    processCharts(0);
  };

  const chartOptions = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Work Permit Charts" },
    },
  };

  const handleTotalClick = (dataType) => {
    setSelectedDataType(dataType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const pieData = {
    labels: Object.keys(workPermitTypes),
    datasets: [
      {
        data: Object.values(workPermitTypes).map((d) => d.issued),
        backgroundColor: Object.keys(workPermitTypes).map(
          () =>
            `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255
            )}, ${Math.floor(Math.random() * 255)}, 0.5)`
        ),
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${context.raw}`,
        },
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 3000,
      easing: "easeInOutExpo",
    },
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: "easeInOutQuad",
    },
    scales: {
      x: {
        title: { display: true, text: "Month Year" },
        grid: { display: false },
      },
      y: {
        title: { display: true, text: "Number of meetings" },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { mode: "index", intersect: false },
    },
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: "easeInOutQuad",
    },
    scales: {
      x: { grid: { display: false } },
      y: {
        beginAtZero: true,
        title: { display: true, text: "Number of Meetings" },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { mode: "index", intersect: false },
    },
  };

  const handleFullScreen = (index) => {
    if (fullScreenChart === index) {
      setFullScreenChart(null);
    } else {
      setFullScreenChart(index);
    }
  };

  const handleResize = (index) => {
    const newSizes = [...chartSizes];
    newSizes[index] = {
      width: newSizes[index].width === "100%" ? "80%" : "100%",
      height: newSizes[index].height === 300 ? 400 : 300,
    };
    setChartSizes(newSizes);
  };

  const renderChart = (index, title, chartComponent) => {
    const isFullScreen = fullScreenChart === index;
    const ChartContainer = isFullScreen ? FullScreenContainer : Paper;

    return (
      <>
        <ChartContainer elevation={3} sx={{ p: 2, position: "relative" }}>
          <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
            {console.log("chartComponent", chartComponent)}
            <IconButton
              onClick={() => handleClickOpen(index, title, chartComponent)}
            >
              <FullscreenIcon />
            </IconButton>
            {/* <IconButton onClick={() => handleFullScreen(index)}>
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton> */}
            {!isFullScreen && (
              <IconButton onClick={() => handleResize(index)}>
                <AspectRatioIcon />
              </IconButton>
            )}
          </Box>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{ fontSize: "0.895rem" }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              height: isFullScreen
                ? "calc(100% - 60px)"
                : chartSizes[index].height,
              width: "100%",
            }}
          >
            {chartComponent}
          </Box>
        </ChartContainer>
      </>
    );
  };

  const renderAdditionalInfo = () => {
    if (currentChart?.title === "Monthly work permit Reported") {
      return (
        <>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Total Work Permits: {totals.totalIssued}
          </Typography>
          <Typography variant="body1">
            Average Work Permits per Month:{" "}
            {(totals.totalIssued / monthlyData.labels.length).toFixed(2)}
          </Typography>
          {/* Add more relevant information */}
        </>
      );
    }
    // Add conditions for other chart types
    return null;
  };

  const handleClickOpen = (index, title, chartComponent) => {
    setCurrentChart({ index, title, component: chartComponent });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentChart(null);
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        Work Permit Report
      </Typography>
  
      <Box mb={2}>
        <Grid container spacing={2}>
        <CustomCard
                bannerText="Work Permit"
                bannerColor="#ff5722"
                bannerTextColor="#f8f9fa"
                onOpenClick={() => handleTotalClick("issued")}
                viewMoreText="View More"
                openTitle="Issued"
                openValue= {totals.totalIssued}
                closedTitle="Closed"
                closedValue= {totals.totalClosed}
                onCloseClick={() => handleTotalClick("closed")}
                subtitle="Issued"
           
              />
              <CustomCard
                bannerText="Work Permit"
                bannerColor="#ffb703"
                bannerTextColor="#f8f9fa"
                onOpenClick={() => handleTotalClick("inspected")}
                openTitle="Inspected"
                openValue= {totals.totalInspected}
                closedTitle="Compiled"
                closedValue={totals.totalComplied}
                onCloseClick={() => handleTotalClick("compiled")}
                subtitle="Inspected"npm
              />
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {currentChart?.title} - Full Screen
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {currentChart?.component}
          {renderAdditionalInfo()}
          {/* Add additional information here */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            Additional information about this chart can be displayed here.
          </Typography>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            mb: { xs: 2, md: 0 },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </LocalizationProvider>
          {/* </Grid>
        <Grid item xs={12} md={4}> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
          {/* </Grid> */}
        </Box>
        {/* <Grid item xs={12} md={4}>
          <Button variant="contained" color="primary" onClick={fetchData} fullWidth>
            Generate Charts
          </Button>
        </Grid>
      </Grid>       */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportPDF}
          startIcon={<DownloadIcon />}
          sx={{ minWidth: 150 }}
        >
          Export as PDF
        </Button>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} ref={(el) => (chartRefs.current[0] = el)}>
              {renderChart(
                0,
                "Monthly work permit Reported",
                monthlyData ? (
                  <Line data={monthlyData} options={lineChartOptions} />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )
              )}
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  background:'black',
                  color:"white"
                }}
              >
                <Button onClick={() => handleTotalClick("issued")}>
                  <Typography variant="h6" align="center" color={"orange"} gutterBottom>
                    Total Issued:
                  </Typography>
                  <Typography variant="h3" align="center" color={"orange"}>
                    {totals.totalIssued}
                  </Typography>
                </Button>
                <Button onClick={() => handleTotalClick("closed")}>
                  <Typography variant="h6" align="center" color={"orange"} gutterBottom>
                    Total Closed:
                  </Typography>
                  <Typography variant="h3" align="center" color={"orange"}>
                    {totals.totalClosed}
                  </Typography>
                </Button>
              </Paper>
            </Grid> */}
          </Grid>

          <Grid container spacing={3} pt={3}>
            {/* <Grid item xs={12} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  background:"black"
                }}
              >
                <Button onClick={() => handleTotalClick("inspected")}>
                  <Typography variant="h6" align="center" color={"orange"} gutterBottom>
                    Total Inspected:
                  </Typography>
                  <Typography variant="h3" align="center" color={"orange"}>
                    {totals.totalInspected}
                  </Typography>
                </Button>
              </Paper>
            </Grid> */}
            {/* Pie Chart for Work Permit Types */}
            {/* {console.log('pieData', pieData)} */}

          <Grid container spacing={3} pt={3}>
            {Object.entries(chartData).map(([key, data], index) => (
              <Grid item xs={12} md={6} key={key}>
                <Paper
                  elevation={3}
                  ref={(el) => (chartRefs.current[index + 2] = el)}
                >
                  {renderChart(
                    1,
                    key.split(/(?=[A-Z])/).join(" "),
                    data ? (
                      <Bar data={data} options={chartOptions} />
                    ) : (
                      <Typography align="center" color="error">
                        No data available
                      </Typography>
                    )
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
            <Grid item xs={12} md={6} ref={(el) => (chartRefs.current[1] = el)}>
              {renderChart(
                2,
                "Work Permit Types Reported",
                Object.keys(workPermitTypes).length > 0 ? (
                  <Pie data={pieData} options={pieOptions} />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )
              )}
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  background:"black"
                }}
              >
                <Button onClick={() => handleTotalClick("complied")}>
                  <Typography variant="h6" align="center" color={"orange"} gutterBottom>
                    Total Compiled:
                  </Typography>
                  <Typography variant="h3" align="center" color={"orange"}>
                    {totals.totalComplied}
                  </Typography>
                </Button>
              </Paper>
            </Grid> */}
          </Grid>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle>Detailed Data</DialogTitle>
            <DialogContent>
              <DetailedDataTable
                company_name={company_name}
                startDate={startDate.toDate()}
                endDate={endDate.toDate()}
                dataType={selectedDataType}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};
export default WorkPermitChart;
