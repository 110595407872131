import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getDoc } from "firebase/firestore";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import UserRouter from "./Screens/UserRouter";
import AdminRouter from "./Screens/AdminRouter";
import Profile from "./Screens/Profile";
import {SpeedInsights} from '@vercel/speed-insights/react';
import CompanyList from "./Screens/CompanyList";
import NavBarScreens from "./Screens/NavBarScreens/NavBarScreens";
import Home from "./Screens/NavBarScreens/Home";
//import AdminRouterWrapper from './Screens/AdminRouterWrapper';
import { getLoginStatusLocally, getUIDLocally } from "./utils/userUtils";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import  theme  from "./theme";

const drawerWidth = 260;

const App = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  // Use the useLocation hook to get the location object
  //    const location = useLocation();
  //const [companyName, setCompanyName] = useState(''); // Add this line

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const isLoggedIn = await getLoginStatusLocally();
        console.log("isLoggedIn", isLoggedIn);
        if (isLoggedIn) {
          const userId = await getUIDLocally();
          console.log("userId", userId);
          const userDocRef = firebase
            .firestore()
            .collection("peoples")
            .doc("users")
            .collection("users")
            .doc(userId);
          const userSnap = await getDoc(userDocRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            //const { company_name, email, permissions, role, user_name } = userData;
            setUser(userData);
            // setIsAdmin(role === 'admin');
            setIsAdmin(false);
          } else {
            setUser(true);
            setIsAdmin(true);
          }
        } else {
          setUser(null);
          // setUser(true);
          setIsAdmin(false);
        }
      } catch (err) {
        console.log(err);
        setUser(null);
        setIsAdmin(false);
      }
    };
    checkLoginStatus();
  }, []);
  //..
  // const isAuthenticated = () => {
  //   // Check if user data exists in localStorage
  //   return localStorage.getItem('userData') !== null;
  // };

  // const getUserRole = () => {
  //   const userData = JSON.parse(localStorage.getItem('userData'));
  //   return userData ? userData.role : null;
  // };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <GlobalScrollbarStyles/> */}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <Routes>
            <Route path="/" element={ user ? ( isAdmin ? ( <Navigate to="/company-list" replace /> 
            ) : ( <Navigate to="/user/user" replace /> )
            ) : ( <Home /> ) } />
            <Route path="/*" element={<NavBarScreens />} />

            <Route path="/profile" element={<Profile />} />

            <Route path="/company-list" element={<CompanyList />} />

            <Route path="/admin/*" element={<AdminRouter />} />
            <Route path="/admin/dashboard/*" element={<AdminRouter />} />
            <Route path="/user/user/*" element={<UserRouter />} />
            <Route path="/user/manager/*" element={
                //  <ManagerRouter />
                <UserRouter />
              }
            />
          </Routes>
        </Router>
      </MuiPickersUtilsProvider>
    <SpeedInsights/>
    </ThemeProvider>
  );
};

export default App