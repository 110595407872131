import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { FaChartBar } from 'react-icons/fa';
import { Box } from '@mui/system';
import { Button, CardContent, Grid, Tab, Tabs, Typography } from '@mui/material';

// Import your chart components here
import NearMissChart from "../charts/NearMissChart";
import UnsafeActChart from "../charts/UnsafeActChart";
import AccidentReportChart from "../charts/AccidentReportChart";
import WeeklySafetyMeetingChart from "../charts/WeeklySafetyMeetingChart";
import FireInjuryChart from "../charts/FireInjuryChart";
import PPEGrievancesChart from "../charts/PPEGrievancesChart";
import KaizenReportChart from "../charts/KaizenReportChart";
import WorkPermitChart from "../charts/WorkPermitChart";

const AdminDashboard = ({ companyName, permissions }) => {
  const [activeTab, setActiveTab] = useState(0);

  const reportTypes = [
    { name: 'Near Miss Report', path: 'near-miss-report-chart', permission: 'Near Miss Report' },
    { name: 'Unsafe Act Report', path: 'unsafe-act-report-chart', permission: 'Unsafe Act' },
    { name: 'Accident Report', path: 'accident-report-chart', permission: 'Accident Report' },
    { name: 'Weekly Safety Meeting', path: 'weekly-safety-meeting-chart', permission: 'Weekly Safety Meeting' },
    { name: 'Fire Injury Report', path: 'fire-injury-report-chart', permission: 'Fire Injury' },
    { name: 'PPE Grievances Report', path: 'ppe-grievances-report-chart', permission: 'PPE Grievances' },
    { name: 'Kaizen Report', path: 'kaizen-report-chart', permission: 'Kaizen Reports' },
    { name: 'Work Permit', path: 'work-permit-chart', permission: 'Work Permit' },
  ];

  // const reportTypes = [
  //   {
  //     name: "Unsafe Act Report",
  //     component: UnsafeActChart,
  //     permission: "Unsafe Act",
  //   },
  //   {
  //     name: "Near Miss Report",
  //     component: NearMissChart,
  //     permission: "Near Miss Report",
  //   },
  //   {
  //     name: "Fire Injury Report",
  //     component: FireInjuryChart,
  //     permission: "Fire Injury",
  //   },
  //   {
  //     name: "Accident Report",
  //     component: AccidentReportChart,
  //     permission: "Accident Report",
  //   },
  //   {
  //     name: "Work Permit",
  //     component: WorkPermitChart,
  //     permission: "Work Permit",
  //   },
  //   {
  //     name: "Kaizen Report",
  //     component: KaizenReportChart,
  //     permission: "Kaizen Reports",
  //   },
  //   {
  //     name: "PPE Grievances Report",
  //     component: PPEGrievancesChart,
  //     permission: "PPE Grievances",
  //   },
  //   {
  //     name: "Weekly Safety Meeting",
  //     component: WeeklySafetyMeetingChart,
  //     permission: "Weekly Safety Meeting",
  //   }
  // ];

  // const handleTabChange = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  // const filteredReports = reportTypes.filter(report =>
  //   permissions.includes(report.permission)
  // );

  // const ActiveChartComponent = filteredReports[activeTab]?.component;
  
  return (
    <Box sx={{width:"100%" }}>
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2} textAlign={"center"}>
        <Typography variant="h4" fontWeight="bold">
          Welcome to {companyName} Dashboard
        </Typography>
      </Box>   
    <Grid container spacing={2}>
      {reportTypes.map((report, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{backgroundColor:"#526D82"}}>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" color={'#ffff'}>
                {report.name === 'Fire Injury Report' ? 'Fire Incident Report' : report.name}
              </Typography>
              <Button
                component={Link} 
                to={report.path} 
                variant="contained" 
                color="primary" 
                startIcon={<FaChartBar />}
                sx={{ mt: 2 }}
              >
                View Chart
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
  );

  // return (
  //   <Box sx={{ width: '100%'}}>
  //     <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2} textAlign={"center"}>
  //       <Typography variant="h4" fontWeight="bold">
  //         Dashboard
  //       </Typography>
  //     </Box>

  //     <Box>
  //       <Tabs
  //         value={activeTab}
  //         onChange={handleTabChange}
  //         variant="scrollable"
  //         scrollButtons="auto"
  //         aria-label="report tabs"
  //       >
  //         {filteredReports.map((report, index) => (
  //           <Tab
  //             key={index}
  //             label={report.name}
  //             sx={{
  //           "&.Mui-selected": {
  //             backgroundColor: "black",
  //             color: "white",
  //           },
  //           transition: 'transform 0.3s ease-in-out',
  //       '&:hover': {
  //         transform: 'scale(1.08)',
  //         backgroundColor: "white",
  //         color:'black'
  //       },
  //         }}
  //           />
  //         ))}
  //       </Tabs>
  //     </Box>

      
  //       {ActiveChartComponent && (
  //         <ActiveChartComponent company_name={companyName}/>
  //       )}
  
  //   </Box>
  // );

};
export default AdminDashboard;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FaChartBar } from 'react-icons/fa';
// import { Box, Button, Tab, Tabs, Typography } from '@mui/material';

// const AdminDashboard = ({ companyName }) => {
//   const [value, setValue] = useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const reportTypes = [
//     { name: 'Near Miss Report', path: 'near-miss-report-chart', permission: 'Near Miss Report' },
//     { name: 'Unsafe Act Report', path: 'unsafe-act-report-chart', permission: 'Unsafe Act' },
//     { name: 'Accident Report', path: 'accident-report-chart', permission: 'Accident Report' },
//     { name: 'Weekly Safety Meeting', path: 'weekly-safety-meeting-chart', permission: 'Weekly Safety Meeting' },
//     { name: 'Fire Injury Report', path: 'fire-injury-report-chart', permission: 'Fire Injury' },
//     { name: 'PPE Grievances Report', path: 'ppe-grievances-report-chart', permission: 'PPE Grievances' },
//     { name: 'Kaizen Report', path: 'kaizen-report-chart', permission: 'Kaizen Reports' },
//     { name: 'Work Permit', path: 'work-permit-chart', permission: 'Work Permit' },
//   ];

//   return (
//     <Box sx={{ width: "100%" }}>
//       <Box display="flex" justifyContent="center" alignItems="center" mb={2} textAlign="center">
//         <Typography variant="h4" fontWeight="bold">
//           Welcome to {companyName} Dashboard
//         </Typography>
//       </Box>

//       <Tabs
//         value={value}
//         onChange={handleChange}
//         variant="scrollable"
//         scrollButtons="auto"
//         aria-label="report types tabs"
//       >
//         {reportTypes.map((report, index) => (
//           <Tab
//             key={index}
//             label={report.name === 'Fire Injury Report' ? 'Fire Incident Report' : report.name}
//           />
//         ))}
//       </Tabs>

//       {reportTypes.map((report, index) => (
//         <Box
//           key={index}
//           role="tabpanel"
//           hidden={value !== index}
//           id={`tabpanel-${index}`}
//           aria-labelledby={`tab-${index}`}
//         >
//           {value === index && (
//             <Box sx={{ p: 3 }}>
//               <Button
//                 component={Link}
//                 to={report.path}
//                 variant="contained"
//                 color="primary"
//                 startIcon={<FaChartBar />}
//               >
//                 View Chart
//               </Button>
//             </Box>
//           )}
//         </Box>
//       ))}
//     </Box>
//   );
// };

// export default AdminDashboard;
// import React, { useState } from 'react';
// import { Box, Tab, Tabs, Typography } from '@mui/material';
// import { Outlet, useNavigate, useLocation } from 'react-router-dom';

// const AdminDashboard = ({ companyName }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [value, setValue] = useState(0);

//   const reportTypes = [
//     { name: 'Near Miss Report', path: 'near-miss-report-chart' },
//     { name: 'Unsafe Act Report', path: 'unsafe-act-report-chart' },
//     { name: 'Accident Report', path: 'accident-report-chart' },
//     { name: 'Weekly Safety Meeting', path: 'weekly-safety-meeting-chart' },
//     { name: 'Fire Incident Report', path: 'fire-injury-report-chart' },
//     { name: 'PPE Grievances Report', path: 'ppe-grievances-report-chart' },
//     { name: 'Kaizen Report', path: 'kaizen-report-chart' },
//     { name: 'Work Permit', path: 'work-permit-chart' },
//   ];

//   React.useEffect(() => {
//     const currentPath = location.pathname.split('/').pop();
//     const index = reportTypes.findIndex(report => report.path === currentPath);
//     if (index !== -1) {
//       setValue(index);
//     }
//   }, [location]);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//     navigate(reportTypes[newValue].path);
//   };

//   return (
//     <Box sx={{ width: "100%" }}>
//       <Box display="flex" justifyContent="center" alignItems="center" mb={2} textAlign="center">
//         <Typography variant="h4" fontWeight="bold">
//           Welcome to {companyName} Dashboard
//         </Typography>
//       </Box>

//       <Tabs
//         value={value}
//         onChange={handleChange}
//         variant="scrollable"
//         scrollButtons="auto"
//         aria-label="report types tabs"
//       >
//         {reportTypes.map((report, index) => (
//           <Tab key={index} label={report.name} />
//         ))}
//       </Tabs>

//       <Box sx={{ p: 3 }}>
//         <Outlet />
//       </Box>
//     </Box>
//   );
// };

// export default AdminDashboard;

