// import React, { useState, useEffect } from 'react';
// import {
//   Container,
//   Typography,
//   TextField,
//   Button,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Grid,
//   Card,
//   CardContent,
// } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
// import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
// import { firestore } from '../../firebase/firebaseConfig';
// import { collection, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore';
// import { createUserWithEmailAndPassword } from 'firebase/auth';
// import { Box } from '@mui/system';

// const useStyles = makeStyles((theme) => ({
//   container: {
//     marginTop: theme.spacing(4),
//   },
//   searchField: {
//     marginBottom: theme.spacing(2),
//   },
//   userCard: {
//     marginBottom: theme.spacing(2),
//   },
//   formControl: {
//     minWidth: 120,
//     width: '100%',
//     marginBottom: theme.spacing(2),
//   },
// }));

// const ManageUsers = ({ companyName }) => {
//   const classes = useStyles();
//   const [users, setUsers] = useState([]);
//   const [searchText, setSearchText] = useState('');
//   const [openModal, setOpenModal] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [userName, setUserName] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [role, setRole] = useState('user');
//   const [selected, setSelected] = useState([]);
//   const [errors, setErrors] = useState({});

//   const formlistwithAuditor = [
//     'Accident Report', 'Fire Injury', 'Near Miss Report', 'PPE Grievances',
//     'Unsafe Act', 'Work Permit', 'Work Permit Auditor', 'Kaizen Reports',
//     'Weekly Safety Meeting'
//   ];

//   const formlistwithoutAuditor = formlistwithAuditor.filter(item => item !== 'Work Permit Auditor');

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = async () => {
//     try {
//       const usersRef = collection(firestore, 'company_data', companyName, companyName, 'for_user', 'users_information');
//       const usersSnapshot = await getDocs(usersRef);
//       const usersData = usersSnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setUsers(usersData);
//     } catch (error) {
//       console.error('Error fetching users:', error);
//     }
//   };

//   const validate = () => {
//     const errors = {};
//     if (!userName) errors.userName = 'User Name is required';
//     if (!email) {
//       errors.email = 'Email is required';
//     } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
//       errors.email = 'Invalid email address';
//     }
//     if (!editMode) {
//       if (!password) {
//         errors.password = 'Password is required';
//       } else if (password.length < 8) {
//         errors.password = 'Password should be at least 8 characters long';
//       }
//       if (password !== confirmPassword) {
//         errors.confirmPassword = 'Passwords do not match';
//       }
//     }
//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleRegisterUser = async () => {
//     if (validate()) {
//       try {
//         const userCredential = await createUserWithEmailAndPassword(email, password);
//         const userId = userCredential.user.uid;
//   console.log("sdusd",userCredential);
//         await setDoc(doc(firestore, 'peoples', 'users', 'users', userId), {
//           user_name: userName,
//           email,
//           password,
//           role,
//           company_name: companyName,
//           permissions: selected,
//         });

//         await setDoc(doc(firestore, 'company_data', companyName, companyName, 'for_user', 'users_information', userId), {
//           user_name: userName,
//           email,
//           role,
//           permissions: selected,
//         });

//         alert('User registered successfully');
//         handleCloseModal();
//         fetchUsers();
//       } catch (error) {
//         console.error('Error registering user:', error);
//         alert('Error registering user');
//       }
//     }
//   };

//   const handleEditUser = async () => {
//     if (validate()) {
//       try {
//         const userId = selectedUser.id;
//         await setDoc(doc(firestore, 'peoples', 'users', 'users', userId), {
//           role,
//           permissions: selected,
//         }, { merge: true });

//         await setDoc(doc(firestore, 'company_data', companyName, companyName, 'for_user', 'users_information', userId), {
//           role,
//           permissions: selected,
//         }, { merge: true });

//         alert('User edited successfully');
//         handleCloseModal();
//         fetchUsers();
//       } catch (error) {
//         console.error('Error editing user:', error);
//         alert('Error editing user');
//       }
//     }
//   };

//   const handleDeleteUser = async (userId) => {
//     if (window.confirm('Are you sure you want to delete this user?')) {
//       try {
//         await deleteDoc(doc(firestore, 'peoples', 'users', 'users', userId));
//         await deleteDoc(doc(firestore, 'company_data', companyName, companyName, 'for_user', 'users_information', userId));
//         alert('User deleted successfully');
//         fetchUsers();
//       } catch (error) {
//         console.error('Error deleting user:', error);
//         alert('Error deleting user');
//       }
//     }
//   };

//   const handleOpenModal = (user = null) => {
//     if (user) {
//       setEditMode(true);
//       setSelectedUser(user);
//       setUserName(user.user_name);
//       setEmail(user.email);
//       setRole(user.role);
//       setSelected(user.permissions || []);
//     } else {
//       setEditMode(false);
//       setSelectedUser(null);
//       setUserName('');
//       setEmail('');
//       setPassword('');
//       setConfirmPassword('');
//       setRole('user');
//       setSelected([]);
//     }
//     setOpenModal(true);
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false);
//     setErrors({});
//   };

//   const filteredUsers = users.filter(
//     (user) => user.email.toLowerCase().includes(searchText.toLowerCase()) || user.user_name.toLowerCase().includes(searchText.toLowerCase()),
//   );

//   return (
//     <Box sx={{margin:4}}>
//       <Typography variant="h4" gutterBottom>
//         Manage Users
//       </Typography>
//       <TextField
//         className={classes.searchField}
//         label="Search by email or name"
//         variant="outlined"
//         fullWidth
//         value={searchText}
//         onChange={(e) => setSearchText(e.target.value)}
//       />
//       <List>
//         {filteredUsers.map((user) => (
//           <Card key={user.id} className={classes.userCard}>
//             <CardContent>
//               <Grid container alignItems="center" justifyContent="space-between">
//                 <Grid item xs={8}>
//                   <Typography variant="h6">{user.user_name}</Typography>
//                   <Typography variant="body2" color="textSecondary">{user.email}</Typography>
//                   <Typography variant="body2" color="textSecondary">Role: {user.role}</Typography>
//                 </Grid>
//                 <Grid item>
//                   <IconButton edge="end" aria-label="edit" onClick={() => handleOpenModal(user)}>
//                     <EditIcon />
//                   </IconButton>
//                   <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteUser(user.id)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </Grid>
//               </Grid>
//             </CardContent>
//           </Card>
//         ))}
//       </List>
//       <Button variant="contained" color="primary" onClick={() => handleOpenModal()}>
//         Register User
//       </Button>

//       <Dialog open={openModal} onClose={handleCloseModal}>
//         <DialogTitle>{editMode ? 'Edit User' : 'Register User'}</DialogTitle>
//         <DialogContent>
//           {!editMode && (
//             <>
//               <TextField
//                 autoFocus
//                 margin="dense"
//                 label="User Name"
//                 type="text"
//                 fullWidth
//                 value={userName}
//                 onChange={(e) => setUserName(e.target.value)}
//                 error={!!errors.userName}
//                 helperText={errors.userName}
//               />
//               <TextField
//                 margin="dense"
//                 label="Email Address"
//                 type="email"
//                 fullWidth
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 error={!!errors.email}
//                 helperText={errors.email}
//               />
//               <TextField
//                 margin="dense"
//                 label="Password"
//                 type="password"
//                 fullWidth
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 error={!!errors.password}
//                 helperText={errors.password}
//               />
//               <TextField
//                 margin="dense"
//                 label="Confirm Password"
//                 type="password"
//                 fullWidth
//                 value={confirmPassword}
//                 onChange={(e) => setConfirmPassword(e.target.value)}
//                 error={!!errors.confirmPassword}
//                 helperText={errors.confirmPassword}
//               />
//             </>
//           )}
//           <FormControl className={classes.formControl}>
//             <InputLabel>Role</InputLabel>
//             <Select
//               value={role}
//               onChange={(e) => setRole(e.target.value)}
//             >
//               <MenuItem value="user">User</MenuItem>
//               <MenuItem value="manager">Manager</MenuItem>
//             </Select>
//           </FormControl>
//           <FormControl className={classes.formControl}>
//             <InputLabel>Permissions</InputLabel>
//             <Select
//               multiple
//               value={selected}
//               onChange={(e) => setSelected(e.target.value)}
//               renderValue={(selected) => selected.join(', ')}
//             >
//               {(role === 'manager' ? formlistwithAuditor : formlistwithoutAuditor).map((name) => (
//                 <MenuItem key={name} value={name}>
//                   {name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseModal} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={editMode ? handleEditUser : handleRegisterUser} color="primary">
//             {editMode ? 'Save' : 'Register'}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default ManageUsers;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  List,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { firestore, auth } from '../../firebase/firebaseConfig'; // Make sure this import is correct
import { 
  collection, 
  getDocs, 
  doc, 
  setDoc, 
  deleteDoc,
  query,
  where 
} from 'firebase/firestore';
import { 
  createUserWithEmailAndPassword,
  deleteUser,
  signInWithEmailAndPassword 
} from 'firebase/auth';

const formlistwithAuditor = [
  'Accident Report', 'Fire Injury', 'Near Miss Report', 'PPE Grievances',
  'Unsafe Act', 'Work Permit', 'Work Permit Auditor', 'Kaizen Reports',
  'Weekly Safety Meeting'
];

const formlistwithoutAuditor = formlistwithAuditor.filter(item => item !== 'Work Permit Auditor');

const ManageUsers = ({ companyName }) => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: 'user',
    selected: []
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, [companyName]);

  const fetchUsers = async () => {
    try {
      const usersRef = collection(firestore, 'company_data', companyName, companyName, 'for_user', 'users_information');
      const usersSnapshot = await getDocs(usersRef);
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.userName) newErrors.userName = 'User Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (!editMode) {
      if (!formData.password) {
        newErrors.password = 'Password is required';
      } else if (formData.password.length < 8) {
        newErrors.password = 'Password should be at least 8 characters long';
      }
      if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = 'Passwords do not match';
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value
    });
  };

  const handleRegisterUser = async () => {
    if (!validate()) return;
    
    setIsLoading(true);
    try {
      // Create the user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      
      const userData = {
        user_name: formData.userName,
        email: formData.email,
        role: formData.role,
        permissions: formData.selected,
        company_name: companyName,
      };

      // Save user data to both collections
      await Promise.all([
        setDoc(doc(firestore, 'peoples', 'users', 'users', userCredential.user.uid), userData),
        setDoc(doc(firestore, 'company_data', companyName, companyName, 'for_user', 'users_information', userCredential.user.uid), userData)
      ]);

      alert('User registered successfully');
      handleCloseModal();
      fetchUsers();
    } catch (error) {
      console.error('Error registering user:', error);
      alert(error.message || 'Error registering user');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditUser = async () => {
    if (!validate()) return;
    
    setIsLoading(true);
    try {
      const userId = selectedUser.id;
      const updateData = {
        role: formData.role,
        permissions: formData.selected,
      };

      await Promise.all([
        setDoc(doc(firestore, 'peoples', 'users', 'users', userId), updateData, { merge: true }),
        setDoc(doc(firestore, 'company_data', companyName, companyName, 'for_user', 'users_information', userId), updateData, { merge: true })
      ]);

      alert('User updated successfully');
      handleCloseModal();
      fetchUsers();
    } catch (error) {
      console.error('Error updating user:', error);
      alert(error.message || 'Error updating user');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;
    
    setIsLoading(true);
    try {
      await Promise.all([
        deleteDoc(doc(firestore, 'peoples', 'users', 'users', userId)),
        deleteDoc(doc(firestore, 'company_data', companyName, companyName, 'for_user', 'users_information', userId))
      ]);
      
      alert('User deleted successfully');
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
      alert(error.message || 'Error deleting user');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = (user = null) => {
    if (user) {
      setEditMode(true);
      setSelectedUser(user);
      setFormData({
        userName: user.user_name,
        email: user.email,
        password: '',
        confirmPassword: '',
        role: user.role || 'user',
        selected: user.permissions || []
      });
    } else {
      setEditMode(false);
      setSelectedUser(null);
      setFormData({
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 'user',
        selected: []
      });
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrors({});
  };

  const filteredUsers = users.filter(
    (user) => 
      user.email?.toLowerCase().includes(searchText.toLowerCase()) || 
      user.user_name?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Manage Users
      </Typography>
      
      <TextField
        label="Search by email or name"
        variant="outlined"
        fullWidth
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        sx={{ mb: 2 }}
      />

      <List>
        {filteredUsers.map((user) => (
          <Card key={user.id} sx={{ mb: 2 }}>
            <CardContent>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={8}>
                  <Typography variant="h6">{user.user_name}</Typography>
                  <Typography variant="body2" color="textSecondary">{user.email}</Typography>
                  <Typography variant="body2" color="textSecondary">Role: {user.role}</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => handleOpenModal(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteUser(user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </List>

      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => handleOpenModal()}
        disabled={isLoading}
      >
        Register User
      </Button>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>{editMode ? 'Edit User' : 'Register User'}</DialogTitle>
        <DialogContent>
          {!editMode && (
            <>
              <TextField
                margin="dense"
                label="User Name"
                fullWidth
                value={formData.userName}
                onChange={handleInputChange('userName')}
                error={!!errors.userName}
                helperText={errors.userName}
              />
              <TextField
                margin="dense"
                label="Email Address"
                type="email"
                fullWidth
                value={formData.email}
                onChange={handleInputChange('email')}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                value={formData.password}
                onChange={handleInputChange('password')}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField
                margin="dense"
                label="Confirm Password"
                type="password"
                fullWidth
                value={formData.confirmPassword}
                onChange={handleInputChange('confirmPassword')}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </>
          )}
          
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Role</InputLabel>
            <Select
              value={formData.role}
              onChange={handleInputChange('role')}
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Permissions</InputLabel>
            <Select
              multiple
              value={formData.selected}
              onChange={handleInputChange('selected')}
              renderValue={(selected) => selected.join(', ')}
            >
              {(formData.role === 'manager' ? formlistwithAuditor : formlistwithoutAuditor).map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleCloseModal} disabled={isLoading}>
            Cancel
          </Button>
          <Button 
            onClick={editMode ? handleEditUser : handleRegisterUser}
            color="primary"
            disabled={isLoading}
          >
            {editMode ? 'Save' : 'Register'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ManageUsers;