import React from 'react';
import { Card, CardContent, Typography, Button, Box, Grid, styled } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  '& .MuiCardContent-root': {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
  }
}));

const StatusBanner = styled(Box)(({ theme, isPending }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: theme.spacing(0.5, 2),
  borderBottomLeftRadius: theme.shape.borderRadius,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  backgroundColor: isPending 
    ? theme.palette.error.main 
    : theme.palette.success.main,
}));

 const StatusCard = ({ 
  status = 'Pending', // Default value
  caseNumber = '', 
  date = '', 
  areaName = '', 
  description = '', 
  handleModalOpen = () => {} 
}) => {
  // Check if status is pending (case-insensitive)
  const isPending = (status || '').toLowerCase() === 'pending';

  // Format date helper function
  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      return new Date(dateString).toLocaleDateString();
    } catch (error) {
      return dateString;
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} spacing={2}>
        <StyledCard elevation={2}>
      <StatusBanner isPending={isPending}>
        <Typography variant="subtitle2">
          {status}
        </Typography>
      </StatusBanner>

      <CardContent>
        <Typography variant="body1" gutterBottom>
          <strong>Case Number:</strong> {caseNumber}
        </Typography>
        
        <Typography variant="body1" gutterBottom>
          <strong>Reported on:</strong> {formatDate(date)}
        </Typography>
        
        <Typography variant="body1" gutterBottom>
          <strong>Area Name:</strong> {areaName}
        </Typography>
        
        <Typography variant="body1" paragraph>
          <strong>Description:</strong>
          <Box component="span" display="block">
            {description?.length > 100
              ? `${description.substring(0, 100)}...`
              : description}
          </Box>
        </Typography>

        <Button
          variant="contained"
          color="primary"
          startIcon={<VisibilityIcon />}
          onClick={handleModalOpen}
          fullWidth
        >
          View Details
        </Button>
      </CardContent>
    </StyledCard>
    </Grid>
    
  );
};

export default StatusCard;

// PropTypes for type checking
StatusCard.propTypes = {
  status: PropTypes.string,
  caseNumber: PropTypes.string,
  date: PropTypes.string,
  areaName: PropTypes.string,
  description: PropTypes.string,
  handleModalOpen: PropTypes.func
};