// //----------------------------------------------------------------------------------------
// import React from 'react';
// import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// import { Nav, Navbar } from 'react-bootstrap';
// import { FaHome, FaUsersCog, FaChartBar, FaBuilding, FaMapMarkedAlt, FaClipboardList, FaHardHat, FaMapMarkerAlt, FaLightbulb } from 'react-icons/fa';
// import { ListGroup } from 'react-bootstrap';

// // const AdminDrawer = () => {
// const AdminDrawer = ({ companyName }) => {
//   const navigate = useNavigate();

//   return (
//     <ListGroup>
//       <ListGroup.Item action onClick={() => navigate(`/admin/dashboard?companyName=${companyName}`)}>
//        Admin Dashboard
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/manage-users?companyName=${companyName}`)}>
//         Manage Users
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/company-data?companyName=${companyName}`)}>
//         Company Data
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/company-zones?companyName=${companyName}`)}>
//         Company Zones
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/add-accident-report?companyName=${companyName}`)}>
//         Add Accident Report
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/add-kaizen-data?companyName=${companyName}`)}>
//         Add kaizen Data
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/zone-leader?companyName=${companyName}`)}>
//         Zone leader
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/add-department-name?companyName=${companyName}`)}>
//         Add Department Name
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/add-reporting-person-designation?companyName=${companyName}`)}>
//         Add Reporting Person designation
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/add-topics-discussed?companyName=${companyName}`)}>
//         Add Topics Discussed
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/zone-assignment?companyName=${companyName}`)}>
//         Zone Assignment
//       </ListGroup.Item>
//       <ListGroup.Item action onClick={() => navigate(`/admin/unsafe-act-unsafe-condition?companyName=${companyName}`)}>
//         Unsafe Act & Unsafe Condition
//       </ListGroup.Item>
//       {/* Add more navigation items as needed */}
//     </ListGroup>
//   );
// };

// export default AdminDrawer;

// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   Divider,
// } from '@mui/material';
// import {
//   FaHome,
//   FaUsersCog,
//   FaChartBar,
//   FaBuilding,
//   FaMapMarkedAlt,
//   FaClipboardList,
//   FaHardHat,
//   FaMapMarkerAlt,
//   FaLightbulb,
// } from 'react-icons/fa';

// const AdminDrawer = ({ companyName }) => {
//   const navigate = useNavigate();

//   const menuItems = [
//     { text: 'Admin Dashboard', icon: <FaHome />, path: `/admin/dashboard?companyName=${companyName}` },
//     { text: 'Manage Users', icon: <FaUsersCog />, path: `/admin/manage-users?companyName=${companyName}` },
//     { text: 'Company Data', icon: <FaBuilding />, path: `/admin/company-data?companyName=${companyName}` },
//     { text: 'Company Zones', icon: <FaMapMarkedAlt />, path: `/admin/company-zones?companyName=${companyName}` },
//     { text: 'Add Accident Report', icon: <FaClipboardList />, path: `/admin/add-accident-report?companyName=${companyName}` },
//     { text: 'Add Kaizen Data', icon: <FaLightbulb />, path: `/admin/add-kaizen-data?companyName=${companyName}` },
//     { text: 'Zone Leader', icon: <FaHardHat />, path: `/admin/zone-leader?companyName=${companyName}` },
//     { text: 'Add Department Name', icon: <FaBuilding />, path: `/admin/add-department-name?companyName=${companyName}` },
//     { text: 'Add Reporting Person Designation', icon: <FaUsersCog />, path: `/admin/add-reporting-person-designation?companyName=${companyName}` },
//     { text: 'Add Topics Discussed', icon: <FaClipboardList />, path: `/admin/add-topics-discussed?companyName=${companyName}` },
//     { text: 'Zone Assignment', icon: <FaMapMarkerAlt />, path: `/admin/zone-assignment?companyName=${companyName}` },
//     { text: 'Unsafe Act & Unsafe Condition', icon: <FaHardHat />, path: `/admin/unsafe-act-unsafe-condition?companyName=${companyName}` },
//   ];

//   return (
//     <List>
//       {menuItems.map((item, index) => (
//         <React.Fragment key={item.text}>
//           <ListItem disablePadding>
//             <ListItemButton onClick={() => navigate(item.path)}>
//               <ListItemIcon>{item.icon}</ListItemIcon>
//               <ListItemText primary={item.text} />
//             </ListItemButton>
//           </ListItem>
//           {index < menuItems.length - 1 && <Divider />} {/* Add dividers between items */}
//         </React.Fragment>
//       ))}
//     </List>
//   );
// };

// export default AdminDrawer;

// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   Divider,
// } from '@mui/material';
// import {
//   FaHome,
//   FaUsersCog,
//   FaChartBar,
//   FaBuilding,
//   FaMapMarkedAlt,
//   FaClipboardList,
//   FaHardHat,
//   FaMapMarkerAlt,
//   FaLightbulb,
// } from 'react-icons/fa';

// const AdminDrawer = ({ companyName }) => {
//   const navigate = useNavigate();

//   const menuItems = [
//     { text: 'Admin Dashboard', icon: <FaHome />, path: `/admin/dashboard?companyName=${companyName}` },
//     { text: 'Manage Users', icon: <FaUsersCog />, path: `/admin/manage-users?companyName=${companyName}` },
//     { text: 'Company Data', icon: <FaBuilding />, path: `/admin/company-data?companyName=${companyName}` },
//     { text: 'Company Zones', icon: <FaMapMarkedAlt />, path: `/admin/company-zones?companyName=${companyName}` },
//     { text: 'Add Accident Report', icon: <FaClipboardList />, path: `/admin/add-accident-report?companyName=${companyName}` },
//     { text: 'Add Kaizen Data', icon: <FaLightbulb />, path: `/admin/add-kaizen-data?companyName=${companyName}` },
//     { text: 'Zone Leader', icon: <FaHardHat />, path: `/admin/zone-leader?companyName=${companyName}` },
//     { text: 'Add Department Name', icon: <FaBuilding />, path: `/admin/add-department-name?companyName=${companyName}` },
//     { text: 'Add Reporting Person Designation', icon: <FaUsersCog />, path: `/admin/add-reporting-person-designation?companyName=${companyName}` },
//     { text: 'Add Topics Discussed', icon: <FaClipboardList />, path: `/admin/add-topics-discussed?companyName=${companyName}` },
//     { text: 'Zone Assignment', icon: <FaMapMarkerAlt />, path: `/admin/zone-assignment?companyName=${companyName}` },
//     { text: 'Unsafe Act & Unsafe Condition', icon: <FaHardHat />, path: `/admin/unsafe-act-unsafe-condition?companyName=${companyName}` },
//   ];

//   return (
//     <List>
//       {menuItems.map((item, index) => (
//         <React.Fragment key={item.text}>
//           <ListItem disablePadding>
//             <ListItemButton onClick={() => navigate(item.path)}>
//               <ListItemIcon>{item.icon}</ListItemIcon>
//               <ListItemText primary={item.text} />
//             </ListItemButton>
//           </ListItem>
//           {index < menuItems.length - 1 && <Divider />} {/* Add dividers between items */}
//         </React.Fragment>
//       ))}
//     </List>
//   );
// };

// export default AdminDrawer;

import React from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Toolbar,
  Typography,
  Box,
  styled,
  Button,
} from "@mui/material";
import {
  FaHome,
  FaUsersCog,
  FaBuilding,
  FaMapMarkedAlt,
  FaClipboardList,
  FaHardHat,
  FaMapMarkerAlt,
  FaLightbulb,
  FaBook,
} from "react-icons/fa";
import {
  getUIDLocally,
  setLoginStatusLocally,
  setUIDLocally,
} from "./../utils/userUtils";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const drawerWidth = 300; // You can adjust the width of the sidebar

// Custom styled drawer
const CustomDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    color: "#fff",
    width: drawerWidth,
    scrollbarWidth: "thin",
    scrollbarColor: "darkgray #ffff",
    boxSizing: "border-box",
    // Ensure overflow for scrollbar to show
    overflowY: "auto", 
  },
  // Apply scrollbar styles globally within the drawer
  "& .MuiDrawer-paper::-webkit-scrollbar": {
    width: "0.2em",
  },
  "& .MuiDrawer-paper::-webkit-scrollbar-track": {
    background: "#1E201E",
  },
  "& .MuiDrawer-paper::-webkit-scrollbar-thumb": {
    background: "darkgray",
    borderRadius: "6px",
  },
  "& .MuiDrawer-paper::-webkit-scrollbar-thumb:hover": {
    background: "#a0a0a0",
  },
  "& .MuiDrawer-paper": {
    backgroundColor: "#3C3D37",
  },
}));

const AdminDrawer = ({ companyName }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      setLoginStatusLocally(false);
      setUIDLocally(null);
      window.location.href = "/";
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const menuItems = [
    {
      text: "Admin Dashboard",
      icon: <FaHome style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/dashboard?companyName=${companyName}`,
    },
    {
      text: "Manage Users",
      icon: <FaUsersCog style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/manage-users?companyName=${companyName}`,
    },
    {
      text: "Company Data",
      icon: <FaBuilding style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/company-data?companyName=${companyName}`,
    },
    {
      text: "Company Zones",
      icon: <FaMapMarkedAlt style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/company-zones?companyName=${companyName}`,
    },
    {
      text: "Conduct Exame",
      icon: <FaBook style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/conduct-exame?companyName=${companyName}`,
    },
    {
      text: "Add Accident Report",
      icon: <FaClipboardList style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/add-accident-report?companyName=${companyName}`,
    },
    {
      text: "Add Kaizen Data",
      icon: <FaLightbulb style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/add-kaizen-data?companyName=${companyName}`,
    },
    {
      text: "Zone Leader",
      icon: <FaHardHat style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/zone-leader?companyName=${companyName}`,
    },
    {
      text: "Add Department Name",
      icon: <FaBuilding style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/add-department-name?companyName=${companyName}`,
    },
    {
      text: "Add Reporting Person Designation",
      icon: <FaUsersCog style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/add-reporting-person-designation?companyName=${companyName}`,
    },
    {
      text: "Add Topics Discussed",
      icon: <FaClipboardList style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/add-topics-discussed?companyName=${companyName}`,
    },
    {
      text: "Zone Assignment",
      icon: <FaMapMarkerAlt style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/zone-assignment?companyName=${companyName}`,
    },
    {
      text: "Unsafe Act & Unsafe Condition",
      icon: <FaHardHat style={{ fontSize: 20, color: "blue" }} />,
      path: `/admin/unsafe-act-unsafe-condition?companyName=${companyName}`,
    },
  ];

  return (
    <Box>
      <CssBaseline />
      <CustomDrawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          backgroundColor: "#0000FF",
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar sx={{textAlign:'center', alignItems:'center', display:'flex',justifyContent:'center'}}>
          <Typography variant="h6" fontWeight={'bold'} color={'#fff'}>
            Admin
          </Typography>
        </Toolbar>
        <Divider />
        <Box>
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={item.text}>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate(item.path)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            color="white"
                          >
                            {item.text}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                {index < menuItems.length - 1}{" "}
                {/* Add dividers between items */}
              </React.Fragment>
            ))}
          </List>
        </Box>
        <Box sx={{ padding: 2, textAlign: "center" }}>
          <Button
            onClick={handleLogout}
            sx={{ backgroundColor: "#F40009", color: "white" }}
          >
            <ListItemText primary="Logout" />
          </Button>
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body1  " color="white">
              &copy; {new Date().getFullYear()} {companyName}.<br /> All rights
              reserved.
            </Typography>
          </Box>
        </Box>
      </CustomDrawer>
    </Box>
  );
};

export default AdminDrawer;
