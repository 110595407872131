import React, { useEffect, useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none',
});

const PreviewImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: 200,
  objectFit: 'contain',
  marginTop: theme.spacing(2),
}));

const ImageUploader = ({ value, onChange, error, helperText, preview }) => {
  const [localPreview, setLocalPreview] = useState(preview);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLocalPreview(reader.result);
      };
      reader.readAsDataURL(file);
      onChange(file);
    }
  };

  useEffect(() => {
    setLocalPreview(preview);
  }, [preview]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
      <Input
        accept="image/*"
        id="contained-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Upload Image
        </Button>
      </label>
      {error && (
        <Typography variant="caption" color="error">
          {helperText}
        </Typography>
      )}
      {localPreview && (
        <PreviewImage src={localPreview} alt="Preview" />
      )}
    </Box>
  );
};
export default ImageUploader;
