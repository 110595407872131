// KaizenReportChart.js
import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CardContent,
  Card,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";

import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomCard from "../../componants/CustomCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const FullScreenContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

const KaizenReportChart = ({ company_name }) => {
  const [startDate, setStartDate] = useState(dayjs().startOf("year"));
  const [endDate, setEndDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [monthlyData, setMonthlyData] = useState(null);
  const [areaData, setAreaData] = useState(null);
  const [sourceData, setSourceData] = useState(null);
  const [totalReported, setTotalReported] = useState(0);
  const [totalImplemented, setTotalImplemented] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [detailedData, setDetailedData] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("");

  const [selectedDataType, setSelectedDataType] = useState("");

  const [fullScreenChart, setFullScreenChart] = useState(null);
  const [chartSizes, setChartSizes] = useState([
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
    { width: "100%", height: 300 },
  ]);

  const [open, setOpen] = useState(false);
  const [currentChart, setCurrentChart] = useState(null);

  const chartRefs = useRef([]);

  const fetchData = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const kaizenCollection = db
      .collection("company_data")
      .doc(company_name)
      .collection(company_name)
      .doc("for_user")
      .collection("kaizen_reports");

    const querySnapshot = await kaizenCollection.get();

    const monthlyDataObj = {};
    const areaDataObj = {};
    const sourceDataObj = {};
    const detailedDataArr = [];
    let reportedCount = 0;
    let implementedCount = 0;

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const {
        kaizenIdentificationDate,
        areaName,
        sourceOfIdentification,
        isPending,
      } = data;
      const date = dayjs(kaizenIdentificationDate);

      if (date.isBetween(startDate, endDate, null, "[]")) {
        // Monthly data
        const monthYear = date.format("MMM YY");
        monthlyDataObj[monthYear] = (monthlyDataObj[monthYear] || 0) + 1;

        // Area data
        if (!areaDataObj[areaName]) {
          areaDataObj[areaName] = { reported: 0, implemented: 0 };
        }
        areaDataObj[areaName].reported++;
        if (!isPending) {
          areaDataObj[areaName].implemented++;
          implementedCount++;
        }
        reportedCount++;

        // Source data
        sourceDataObj[sourceOfIdentification] =
          (sourceDataObj[sourceOfIdentification] || 0) + 1;

        // Detailed data
        detailedDataArr.push({
          caseNumber: data.caseNumber,
          kaizenIdentificationDate: date.format("YYYY-MM-DD"),
          areaName,
          areaManagerName: data.areaManagerName,
          kaizenDescription: data.kaizenDescription,
          identifiedBy: data.identifiedBy,
          sourceOfIdentification,
          isPending,
        });
      }
    });

    setMonthlyData({
      labels: Object.keys(monthlyDataObj).sort((a, b) =>
        dayjs(a, "MMM YY").diff(dayjs(b, "MMM YY"))
      ),
      datasets: [
        {
          label: "Kaizen Reported",
          data: Object.values(monthlyDataObj),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    });

    setAreaData({
      labels: Object.keys(areaDataObj),
      datasets: [
        {
          label: "Reported",
          data: Object.values(areaDataObj).map((d) => d.reported),
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
        {
          label: "Implemented",
          data: Object.values(areaDataObj).map((d) => d.implemented),
          backgroundColor: "rgba(255, 99, 132, 0.6)",
        },
      ],
    });

    setSourceData({
      labels: Object.keys(sourceDataObj),
      datasets: [
        {
          data: Object.values(sourceDataObj),
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(75, 192, 192, 0.6)",
            "rgba(153, 102, 255, 0.6)",
          ],
        },
      ],
    });

    setTotalReported(reportedCount);
    setTotalImplemented(implementedCount);
    setDetailedData(detailedDataArr);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [company_name, startDate, endDate]);

  const handleOpenDialog = (type) => {
    setDialogTitle(
      type === "reported" ? "Reported Kaizens" : "Implemented Kaizens"
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const filteredDetailedData = detailedData.filter((item) =>
    dialogTitle === "Reported Kaizens" ? item.isPending : !item.isPending
  );

  // const exportToExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(detailedData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "DetailedData");
  //   XLSX.writeFile(workbook, `kaizen_detailed_data.xlsx`);
  // };

  const exportToExcel = () => {
    const dataToExport = filteredDetailedData.map(
      ({
        caseNumber,
        kaizenIdentificationDate,
        areaName,
        areaManagerName,
        kaizenDescription,
        identifiedBy,
        sourceOfIdentification,
      }) => ({
        caseNumber,
        kaizenIdentificationDate,
        areaName,
        areaManagerName,
        kaizenDescription,
        identifiedBy,
        sourceOfIdentification,
      })
    );

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "DetailedData");
    XLSX.writeFile(
      workbook,
      `kaizen_${dialogTitle.toLowerCase().replace(" ", "_")}.xlsx`
    );
  };

  const handleExportPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 10;
    let yOffset = margin; // Initial Y offset for the first chart

    const addChartToPDF = (chartRef, callback) => {
      html2canvas(chartRef, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Check if the image fits in the current page, if not add a new page
        if (yOffset + imgHeight + margin > pageHeight) {
          pdf.addPage();
          yOffset = margin;
        }

        pdf.addImage(imgData, "PNG", margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + margin; // Add some space between charts

        callback();
      });
    };

    const processCharts = (index) => {
      if (index < chartRefs.current.length) {
        addChartToPDF(chartRefs.current[index], () => {
          processCharts(index + 1);
        });
      } else {
        pdf.save("kaizen_report_charts.pdf");
      }
    };
    processCharts(0);
  };
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: "easeInOutQuad",
    },
    scales: {
      x: {
        title: { display: true, text: "Month Year" },
        grid: { display: false },
      },
      y: {
        title: { display: true, text: "Number of meetings" },
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { mode: "index", intersect: false },
    },
  };

  const handleTotalClick = (dataType) => {
    setSelectedDataType(dataType);
    setOpenDialog(true);
  };

  const renderChart = (index, title, chartComponent) => {
    const isFullScreen = fullScreenChart === index;
    const ChartContainer = isFullScreen ? FullScreenContainer : Paper;

    return (
      <>
        <ChartContainer elevation={3} sx={{ p: 2, position: "relative" }}>
          <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}>
            {console.log("chartComponent", chartComponent)}
            <IconButton
              onClick={() => handleClickOpen(index, title, chartComponent)}
            >
              <FullscreenIcon />
            </IconButton>
            {/* <IconButton onClick={() => handleFullScreen(index)}>
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton> */}
            {!isFullScreen && (
              <IconButton onClick={() => handleResize(index)}>
                <AspectRatioIcon />
              </IconButton>
            )}
          </Box>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            sx={{ fontSize: "0.895rem" }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              height: isFullScreen
                ? "calc(100% - 60px)"
                : chartSizes[index].height,
              width: "100%",
            }}
          >
            {chartComponent}
          </Box>
        </ChartContainer>
      </>
    );
  };

  const handleResize = (index) => {
    const newSizes = [...chartSizes];
    newSizes[index] = {
      width: newSizes[index].width === "100%" ? "80%" : "100%",
      height: newSizes[index].height === 300 ? 400 : 300,
    };
    setChartSizes(newSizes);
  };
  const handleClickOpen = (index, title, chartComponent) => {
    setCurrentChart({ index, title, component: chartComponent });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentChart(null);
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1500,
      easing: "easeInOutBounce",
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Categories",
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Values",
        },
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
      },
    },
  };
  const pieOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${context.raw}`,
        },
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
      duration: 3000,
      easing: "easeInOutExpo",
    },
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom fontWeight={"bold"}>
        Kaizen Report
      </Typography>

      <Box mb={2}>
        <Grid container spacing={2}>
        <CustomCard
            bannerText="Kaizen"
            bannerColor="#ff5722"
            bannerTextColor="#f8f9fa"
            onOpenClick={() => handleOpenDialog("reported")}
            openTitle="Reported"
            openValue= {totalReported}
            closedTitle="Implemented"
            closedValue={totalImplemented}
            onCloseClick={() => handleOpenDialog("implemented")}
            subtitle="Reported"
  
          />
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">
              {currentChart?.title} - Full Screen
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {currentChart?.component}
          {/* {renderAdditionalInfo()} */}
          {/* Add additional information here */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            Additional information about this chart can be displayed here.
          </Typography>
        </DialogContent>
      </Dialog>

      {/* <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={4}> */}
      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
            mb: { xs: 2, md: 0 },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </LocalizationProvider>
          {/* </Grid>
        <Grid item xs={12} md={4}> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </LocalizationProvider>
          {/* </Grid> */}
        </Box>
        {/* <Grid item xs={12} md={4}>
          <Button variant="contained" color="primary" onClick={fetchData} fullWidth>
            Generate Charts
          </Button>
        </Grid> 
      </Grid> */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportPDF}
          startIcon={<DownloadIcon />}
          sx={{ minWidth: 150 }}
        >
          Export as PDF
        </Button>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <Paper elevation={3} ref={el => chartRefs.current[0] = el}>
                <Typography variant="h6" align="center" gutterBottom>
                  Monthly Kaizen Reported
                </Typography>
                {monthlyData ? (
                  <Line data={monthlyData} options={{
                    responsive: true,
                    scales: {
                      x: { title: { display: true, text: 'Month Year' } },
                      y: { title: { display: true, text: 'Number of Kaizens' } }
                    }
                  }} />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )}
              </Paper>
            </Grid> */}

            <Grid item xs={12} md={12} ref={(el) => (chartRefs.current[0] = el)}>
              {renderChart(
                0,
                "Monthly Kaizen Reported",
                monthlyData ? (
                  <Line data={monthlyData} options={lineChartOptions} />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )
              )}
            </Grid>

            {/* <Grid item xs={12} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button onClick={() => handleOpenDialog("reported")}>
                  <Typography variant="h6" align="center" color={"orange"} gutterBottom>
                    Total reported: {totalReported}
                  </Typography>
                </Button>
                <Button onClick={() => handleOpenDialog("implemented")}>
                  <Typography variant="h6" align="center" color={"green"} gutterBottom>
                    Total Implemented: {totalImplemented}
                  </Typography>
                </Button>
              </Paper>
            </Grid> */}

            {/* <Grid item xs={12}>
              <Paper elevation={3} ref={el => chartRefs.current[1] = el}>
                <Typography variant="h6" align="center" gutterBottom>
                  Area wise Kaizen Reported vs Implemented
                </Typography>
                {areaData ? (
                  <Bar data={areaData} options={{
                    responsive: true,
                    scales: {
                      x: { stacked: true, title: { display: true, text: 'Area' } },
                      y: { stacked: true, title: { display: true, text: 'Number of Kaizens' } }
                    }
                  }} />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )}
              </Paper>
            </Grid> */}
            <Grid item xs={12} md={6} ref={(el) => (chartRefs.current[1] = el)}>
              {renderChart(
                1,
                "Area wise Kaizen Reported vs Implemented",
                areaData ? (
                  <Bar data={areaData} options={barChartOptions} />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )
              )}
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <Paper elevation={3} ref={el => chartRefs.current[2] = el}>
                <Typography variant="h6" align="center" gutterBottom>
                  Source of Kaizen
                </Typography>
                {sourceData ? (
                  <Pie data={sourceData} options={{
                    responsive: true,
                    plugins: { legend: { position: 'right' } }
                  }} />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )}
              </Paper>
            </Grid> */}

            <Grid item xs={12} md={6} ref={(el) => (chartRefs.current[2] = el)}>
              {renderChart(
                2,
                "Source of Kaizen",
                sourceData ? (
                  <Pie data={sourceData} options={pieOptions} />
                ) : (
                  <Typography align="center" color="error">
                    No data available
                  </Typography>
                )
              )}
            </Grid>

            {/* <Grid item xs={12} md={6}>
              <Paper elevation={3}>
                <Box p={3}>
                  <Typography variant="h6" gutterBottom>Totals</Typography>
                  <Typography>
                    <Button color="primary" onClick={() => handleOpenDialog('reported')}>
                      Total Reported: {totalReported}
                    </Button>
                  </Typography>
                  <Typography>
                    <Button color="primary" onClick={() => handleOpenDialog('implemented')}>
                      Total Implemented: {totalImplemented}
                    </Button>
                  </Typography>
                </Box>
              </Paper>
              <Typography>
              <Button variant="contained" color="secondary" onClick={handleExportPDF}>
                Export as PDF
                </Button>
                </Typography>
            </Grid> */}
          </Grid>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Case Number</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Area Name</TableCell>
                      <TableCell>Area Manager Name</TableCell>
                      <TableCell>Kaizen Description</TableCell>
                      <TableCell>Identified By</TableCell>
                      <TableCell>Source of Identification</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDetailedData.map((row, index) => (
                      <TableRow key={row.caseNumber}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.caseNumber}</TableCell>
                        <TableCell>{row.kaizenIdentificationDate}</TableCell>
                        <TableCell>{row.areaName}</TableCell>
                        <TableCell>{row.areaManagerName}</TableCell>
                        <TableCell>{row.kaizenDescription}</TableCell>
                        <TableCell>{row.identifiedBy}</TableCell>
                        <TableCell>{row.sourceOfIdentification}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={exportToExcel} color="primary">
                Export to Excel
              </Button>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default KaizenReportChart;
