import { Box } from '@mui/material'
import React from 'react'

const ExameForm = ( { exameLink } ) => {
  return (
    <Box sx={{width:"100%" }}>
        <iframe src={ exameLink } 
        width="100%" 
        height="600" 
        frameborder="0" 
        marginheight="0" 
        marginwidth="0"
        >
        Loading…
        </iframe>
    </Box>
  )
}

export default ExameForm