import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { setLoginStatusLocally, setUIDLocally } from './../utils/userUtils';

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    setRefreshing(true);
    const firestore = firebase.firestore();
    const companiesCollection = firestore.collection('company_data');
    const querySnapshot = await companiesCollection.get();
    const companyData = [];

    for (const doc of querySnapshot.docs) {
      const companyInfo = doc.data();
      const companyName = companyInfo.name;
      const companyRef = companiesCollection.doc(companyName);
      const userInfoCollectionRef = companyRef.collection(companyName).doc('for_user').collection('users_information');
      let totalActiveUsers = 0;
      try {
        const userInfoSnapshot = await userInfoCollectionRef.get();
        totalActiveUsers = userInfoSnapshot.size;
      } catch (error) {
        console.error('Error getting user info count:', error);
      }
      companyData.push({
        ...companyInfo,
        case_count: companyInfo.case_count,
        total_active_users: totalActiveUsers,
      });
    }

    setCompanies(companyData);
    setRefreshing(false);
  };

  const handleAddCompany = async (e) => {
    e.preventDefault();
    const firestore = firebase.firestore();
    const companyRef = firestore.collection('company_data').doc(companyName);
    await companyRef.set({
      name: companyName,
      total_active_users: 0,
      case_count: 0,
    });

    const companyNameAdminRef = companyRef.collection(companyName).doc('for_admin');
    await companyNameAdminRef.set({ name: 'for_admin' });
    const companyNameUserRef = companyRef.collection(companyName).doc('for_user');
    await companyNameUserRef.set({ name: 'for_user' });

    // Set up other collections and documents as needed
    await companyNameAdminRef.collection('department_name').doc('details').set({});
    // Other collections...
    await companyNameUserRef.collection('work_permit').doc('details').set({});

    setCompanyName('');
    fetchCompanies(); // Refresh the company list after adding a new company
  };

  const handleCompanyCardClick = (companyName) => {
    navigate(`/admin/dashboard?companyName=${companyName}`, { state: { companyName } });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Add Company
          </Typography>
          <form onSubmit={handleAddCompany}>
            <TextField
              label="Company Name"
              variant="outlined"
              fullWidth
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              margin="normal"
            />
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Add Company
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Company List
          </Typography>
          <Grid container spacing={3}>
            {companies.map((company, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card sx={{ cursor: 'pointer' }} onClick={() => handleCompanyCardClick(company.name)}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {company.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Active Users: {company.total_active_users}
                      <br />
                      Case Count: {company.case_count}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {/* Add additional actions or links here if needed */}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompanyList;
