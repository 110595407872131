//----------------------------------------------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AdminDashboard from "./admins/AdminDashboard";
import ManageUsers from "./admins/ManageUsers";
import CompanyData from "./admins/CompanyData";
import CompanyZones from "./admins/CompanyZones";
import AddAccidentReport from "./admins/AddAccidentReport";
import AddKaizenData from "./admins/AddKaizenData";
import ZoneLeader from "./admins/ZoneLeader";
import AddDepartmentName from "./admins/AddDepartmentName";
import AddReportingPersonDesignation from "./admins/AddReportingPersonDesignation";
import AddTopicsDiscussed from "./admins/AddTopicsDiscussed";
import AddPpeTypes from "./admins/AddPpeTypes";
import ZoneAssignment from "./admins/ZoneAssignment";
import UnSafeActUnSafeCondition from "./admins/UnSafeActUnSafeCondition";

// import CompanyList from './CompanyList';
import AdminDrawer from "./AdminDrawer";
import { Container, Row, Col } from "react-bootstrap";

// Import chart components
import NearMissChart from "./charts/NearMissChart";
import UnsafeActChart from "./charts/UnsafeActChart";
import AccidentReportChart from "./charts/AccidentReportChart";
import WeeklySafetyMeetingChart from "./charts/WeeklySafetyMeetingChart";
import FireInjuryChart from "./charts/FireInjuryChart";
import PPEGrievancesChart from "./charts/PPEGrievancesChart";
import KaizenReportChart from "./charts/KaizenReportChart";
import WorkPermitChart from "./charts/WorkPermitChart";
import { Box } from "@mui/system";
import ConductExame from "./admins/ConductExame";

const AdminRouter = () => {
  const [exameLink, setExameLink] = useState('');
  //const [searchparam] = useSearchParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const company_nameq = queryParams.get("companyName");
  // const company_name = companyName;
  const { companyName } = location.state || {};
  const company_name = companyName;
  // console.log('admin router company name',company_name);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      localStorage.setItem("userData", JSON.stringify(location.state));
    } else {
      const storedData = localStorage.getItem("userData");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        navigate(location.pathname, { state: parsedData, replace: true });
      }
    }
  }, [location, navigate]);

  return (
    <Box sx={{  display: 'flex', width: '100%', height: '100vh'}}>
      <Box>
          <AdminDrawer companyName={companyName} />
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Routes>
          {/* <Route path="company-list" element={<CompanyList />} /> */}
          {/* <Route path="dashboard" element={<AdminDashboard />} /> */}
          {company_name && (
            <>
              <Route
                path="dashboard"
                element={
                  <AdminDashboard
                    company_name={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="manage-users"
                element={
                  <ManageUsers
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="company-data"
                element={
                  <CompanyData
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="company-zones"
                element={
                  <CompanyZones
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />

              <Route
                path="conduct-exame"
                element={
                  <ConductExame
                  onAssignExame={setExameLink} companyName={companyName ? companyName : company_nameq}
                  />
                }
              />

              <Route
                path="add-accident-report"
                element={
                  <AddAccidentReport
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="add-kaizen-data"
                element={
                  <AddKaizenData
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="zone-leader"
                element={
                  <ZoneLeader
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="add-department-name"
                element={
                  <AddDepartmentName
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="add-reporting-person-designation"
                element={
                  <AddReportingPersonDesignation
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="add-topics-discussed"
                element={
                  <AddTopicsDiscussed
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="add-ppe-types"
                element={
                  <AddPpeTypes
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="zone-assignment"
                element={
                  <ZoneAssignment
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="unsafe-act-unsafe-condition"
                element={
                  <UnSafeActUnSafeCondition
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />

              <Route
                path="/"
                element={
                  <AdminDashboard
                    companyName={companyName ? companyName : company_nameq}
                  />
                }
              />
              <Route
                path="near-miss-report-chart"
                element={
                  <NearMissChart
                    company_name={company_name ? company_name : companyName}
                  />
                }
              />
              <Route
                path="unsafe-act-report-chart"
                element={<UnsafeActChart company_name={company_name} />}
              />
              <Route
                path="accident-report-chart"
                element={<AccidentReportChart company_name={company_name} />}
              />
              <Route
                path="weekly-safety-meeting-chart"
                element={
                  <WeeklySafetyMeetingChart
                    userId={"jjbjb"}
                    company_name={company_name}
                  />
                }
              />
              <Route
                path="fire-injury-report-chart"
                element={<FireInjuryChart company_name={company_name} />}
              />
              <Route
                path="ppe-grievances-report-chart"
                element={<PPEGrievancesChart company_name={company_name} />}
              />
              <Route
                path="kaizen-report-chart"
                element={<KaizenReportChart company_name={company_name} />}
              />
              <Route
                path="work-permit-chart"
                element={<WorkPermitChart company_name={company_name} />}
              />
            </>
          )}
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminRouter;
